.PatientList-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  box-sizing: border-box;
  .patient-item {
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e6eaf0;
    margin-top: 16px;
    .item-header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 14px 16px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      background: #f5f5f5;
      .name {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .left {
        display: flex;
        align-items: center;
        margin-left: 16px;
      }
    }
    .num {
      display: flex;
      align-items: center;
      .text {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        margin-left: 7px;
      }
    }
    .item-content {
      width: 100%;
      padding: 12px 16px;
      border-bottom: 1px solid #f0f0f0;
      //   .num {
      //     display: flex;
      //     align-items: center;
      //     .text {
      //       font-weight: 400;
      //       font-size: 14px;
      //       color: #243752;
      //       margin-left: 7px;
      //     }
      //   }
      .num + .num {
        margin-top: 12px;
      }
    }
    .item-footer {
      width: 100%;
      padding: 12px 16px;
    }
  }
}

@root-entry-name: default;