@import '../../../../style/vars.less';
@primary-color: #1778ff;

.health_care_order_list_dom {
  max-height: calc(100vh - 93px);
  // max-height: calc(100vh - 43px);
  overflow-y: auto;
  background: #f0f3f5;
  .list {
    padding: 16px 12px 0px;
    display: flex;
    flex-direction: column;
    // gap: 16px;
    .mixin_gap_column(16px);
    align-items: center;
  }
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    padding: 16px 16px 11px;
    line-height: 1;
    width: 100%;
    border-radius: 12px 12px 12px 12px;
    box-sizing: border-box;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .state {
        font-weight: 400;
        font-size: 14px;
      }
      .state1 {
        color: @primary-color;
      }
      .state2 {
        color: #a7afba;
      }
    }
    .line {
      height: 1px;
      background-color: #f5f5f5;
      margin: 12px 0px;
    }
    .infos {
      margin-top: 12px;
      display: flex;
      .label {
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
        min-width: 65px;
      }
      .value {
        font-weight: 400;
        font-size: 13px;
        color: #243752;
        margin-left: 24px;
      }
      .value1 {
        color: @primary-color;
      }
    }
    .price_box {
      display: flex;
      margin-top: -5px;
      align-items: center;
      height: 28px;
      .label {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
      }
      .price {
        font-weight: 600;
        font-size: 12px;
        color: @primary-color;
        margin-left: 24px;
        .unit {
        }
        .price_integer {
          font-size: 18px;
        }
        .price_fractional {
        }
      }
      .pay_btn {
        margin-left: auto;
        width: 92px;
        height: 28px;
        background: @primary-color;
        border-radius: 28px 28px 28px 28px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pay_btn1 {
        margin-left: auto;
        width: 78px;
        height: 28px;
        background: #ffffff;
        border-radius: 28px 28px 28px 28px;
        border: 1px solid @primary-color;
        font-weight: 400;
        font-size: 14px;
        color: @primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .InfiniteScroll_box {
    padding-bottom: 24px;
  }
}

@root-entry-name: default;