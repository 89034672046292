@import '../../../style/vars.less';

.patient-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, #e8f9ff 0%, #f0f3f5 44%);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  .bg {
    position: absolute;
    background-image: url(../../../assets/patient_top1.png);
    background-size: 100% 152px;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .patient_info {
    height: 62px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    .patient_info_content {
      display: flex;
      flex-direction: column;
      .id_card {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    .avatar img {
      width: 60px;
      height: 60px;
      background: #d0eded;
      border-radius: 50%;
      border: 1px solid #aadede;
    }
    .user_name_box {
      display: flex;
      align-items: center;
      .mixin_gap_row(8px);
      margin-left: 8px;
      .user_name {
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
      }
      .user_gender {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
      .user_age {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  .patient-menu {
    height: 92px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    .patient-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .patient-menu-img {
        width: 40px;
        height: 40px;
      }
      .patient-menu-text {
        font-weight: 400;
        font-size: 12px;
        color: #243752;
        margin-top: 8px;
      }
    }
  }
  .nav_title {
    margin-top: 18px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-weight: 500;
      font-size: 16px;
      color: #243752;
    }
  }
  .patient-col {
    margin-bottom: 16px;
  }
  .patient-content {
    width: 100%;
    flex: 1;
    padding: 0px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 44px;
    .patient-info {
      width: 100%;
      //   height: 162px;
      background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      .info-name {
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 8px;
      }
      .info-contnet {
        width: 100%;
        display: flex;
        .info-col {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          position: relative;
          padding-right: 10px;
          display: flex;
          align-items: center;
        }

        .info-col + .info-col {
          padding-left: 10px;
          padding-right: 10px;
          &::after {
            display: block;
            content: '';
            width: 1px;
            height: 8px;
            background: #ebebeb;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
          }
        }
      }
    }
    .indicators {
      width: 100%;
      // min-height: 356px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .indicators-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        .header-title {
          font-weight: bold;
          font-size: 16px;
          color: #243752;
        }
        .header-rigth {
          font-weight: 400;
          font-size: 12px;
          color: #a7afba;
          display: flex;
          align-items: center;
          .text {
            margin-right: 2px;
          }
          .antd-mobile-icon {
            margin-bottom: 2px;
          }
        }
      }

      .indicators-title {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .indicators-abnormol {
        .abnormol-content {
          width: 100%;
          border: 1px solid #ffe0d9;
          border-radius: 6px 6px 6px 6px;
          padding: 0px 12px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          background: #fff8f6;
          .abnormol-item {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            .item-title {
              width: 84px;
              white-space: nowrap;
              font-size: 14px;
              color: #243752;
            }
            .item-num {
              font-size: 14px;
              color: #1dbcc0;
            }
            .abnormol-state {
              font-size: 14px;
              color: #eb5e3d;
            }
          }
          .abnormol-item + .abnormol-item {
            border-top: 1px solid #e6ebe9;
          }
        }
      }
      .indicators-adnormol-myHealth {
        // background-color: aqua;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          width: 25%;
          text-align: center;
        }
      }
      .normol {
        border: 1px solid #cce7e7 !important;
        background: #f5faf8 !important;
        .normol-text {
          color: #00c242 !important;
        }
      }
    }
    .patient-indicator {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      .err-content {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        margin-bottom: 16px;
        .err-header {
          display: flex;
          align-items: center;
          // margin-bottom: 14px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .text {
            font-weight: 500;
            font-size: 16px;
            color: #eb5e3d;
          }
        }
      }
      .success-content {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        .success-header {
          display: flex;
          align-items: center;
          // margin-bottom: 14px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .text {
            font-weight: 500;
            font-size: 16px;
            color: #00c242;
          }
        }
      }
      // .IndicatorItem1 + .IndicatorItem1 {
      //   margin-top: 14px;
      // }
    }
    .render-patient-empty {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 500;
      font-size: 14px;
      color: #7c8797;
      .render-img {
        width: 124px;
        height: 124px;
        margin-bottom: 16px;
      }
    }
  }
  .item-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader-cell {
    width: 32px;
    height: 32px;
  }
  .adm-space-item {
    width: 32px;
    height: 32px;
  }
  .adm-image-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
}

.IndicatorItem1 {
  width: 100%;
  padding: 14px 0px;
  // padding-top: 0px;
  border-bottom: 1px solid #ebebeb;
  .IndicatorItem-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .left {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      width: 50%;
    }
    .rigth {
      .num {
        font-weight: 500;
        font-size: 14px;
        color: #505f75;
        margin-right: 24px;
      }
      .state {
        font-weight: 500;
        font-size: 14px;
        color: #00c242;
        min-width: 28px;
        display: inline-block;
      }
      .errState {
        color: #eb5e3d;
      }
    }
  }
  .IndicatorItem-bottom {
    width: 100%;
    .item {
      font-weight: 400;
      font-size: 12px;
      color: #7c8797;
    }
    .item + .item {
      margin-top: 6px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}

@root-entry-name: default;