@import '../../style/vars.less';

.face_to_face_consultation_request_form {
  width: 100%;
  min-height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    --active-line-color: #1778ff;
    --active-title-color: #1778ff;
    --content-padding: 0px;
    .adm-tabs-tab {
      font-weight: 400;
    }
    .adm-tabs-tab-active {
      font-weight: 500 !important;
    }
    .adm-tabs-header {
      background: #fff;
    }
    .adm-tabs-content {
      flex: 1;
      display: flex;
      .tab_content1 {
        width: 100%;
        min-height: calc(100vh - 43px);
        padding: 0px 20px;
        box-sizing: border-box;
        background: linear-gradient(180deg, #4f7af6 0%, #e5e9f7 100%);
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 124px;
      }
      .tab_content2 {
        height: calc(100vh - 43px);
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        width: 100%;
        background-color: #f0f3f5;
        .list {
          padding: 16px 12px 0px;
          display: flex;
          flex-direction: column;
          // gap: 16px;
          .mixin_gap_column(16px);
          align-items: center;
        }
        .card {
          background: #ffffff;
          box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
          padding: 16px 16px 20px;
          line-height: 1;
          width: 100%;
          border-radius: 12px;
          box-sizing: border-box;
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .card_title {
              display: flex;
              .card_name {
                font-weight: 500;
                font-size: 16px;
                color: #243752;
              }
              .card_gender {
                margin-left: 12px;
                font-weight: 400;
                font-size: 14px;
                color: #243752;
              }
              .card_age {
                margin-left: 12px;
                font-weight: 400;
                font-size: 14px;
                color: #243752;
              }
            }
            .state {
              font-weight: 400;
              font-size: 14px;
            }
            .state1 {
              color: #1778ff;
            }
            .state2 {
              color: #7c8797;
            }
            .state3 {
              color: #e32525;
            }
          }
          .card_line {
            height: 1px;
            background-color: #f5f5f5;
            margin: 12px 0px;
          }
          .infos {
            margin-top: 12px;
            display: flex;
            .label {
              font-weight: 400;
              font-size: 13px;
              color: #7c8797;
              min-width: 65px;
            }
            .value {
              font-weight: 400;
              font-size: 13px;
              color: #243752;
              margin-left: 24px;
            }
            .value1 {
              color: @primary-color;
            }
          }
          .cancel {
            .label {
              color: #e32525;
            }
            .value {
              color: #e32525;
            }
          }
          .bottom_cancel {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #f5f5f5;
            margin-top: 16px;
            padding-top: 12px;
            .btn {
              width: 86px;
              height: 30px;
              background: rgba(23, 120, 255, 0.05);
              border-radius: 59px 59px 59px 59px;
              border: 1px solid #1778ff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 400;
              font-size: 14px;
              color: #1778ff;
            }
          }
        }
        .empty {
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
          text-align: center;
          margin-top: 16px;
        }
      }
    }
  }
  .bg {
    background-image: url(../../assets/Slice10.png);
    background-size: 100%;
    width: 100%;
    height: 248px;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
  }
  .line {
    width: calc(100% + 16px);
    height: 14px;
    background: #2156e7;
    border-radius: 10px 10px 10px 10px;
    margin: 116px auto 0px -8px;
    position: relative;
  }
  .card1 {
    // height: 274px;
    background: #ffffff;
    border-radius: 0px 0px 12px 12px;
    position: relative;
    margin-top: -7px;
    padding-bottom: 16px;
    .icon {
      position: absolute;
      left: 0;
      top: -47px;
      width: 52px;
      height: 52px;
      background-image: url(../../assets/Slice5.png);
      background-size: 100%;
    }

    .top_bg {
      height: 14px;
      background: linear-gradient(
        180deg,
        rgba(0, 68, 253, 0.5) 0%,
        rgba(0, 68, 253, 0) 100%
      );
    }
    .form {
      --border-inner: none;
      --border-top: none;
      --border-bottom: none;
      --prefix-width: none;
      padding: 0px 12px;
      .adm-list-item {
        margin-top: 12px;
        border-radius: 7px 7px 7px 7px;
        border: 1px solid #d6d6d6;
        min-height: 46px;
        font-size: 17px;
      }
      .adm-form-item-label {
        min-width: 85px;
      }
    }
    .placeholder {
      color: var(--adm-color-light);
    }
  }
  .card2 {
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 20px 16px;
    margin-top: 24px;
    box-sizing: border-box;
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #243752;
      text-align: center;
    }
    .content {
      margin-top: 24px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #505f75;
      line-height: 20px;
      margin: 0px;
    }
  }
  .submit_btn_box {
    width: 285px;
    height: 46px;
    background: linear-gradient(180deg, #4995ff 0%, #0764e5 100%);
    border-radius: 59px 59px 59px 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: fixed;
    bottom: 54px;
    left: 0;
    right: 0;
    .submit_btn {
      width: 277px;
      height: 38px;
      background: linear-gradient(180deg, #0764e5 0%, #4995ff 100%);
      border-radius: 59px 59px 59px 59px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 12px;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.35);
    }
  }
}
.my_picker {
  .adm-picker-header-title {
    font-weight: 500;
    font-size: 16px;
    color: #243752;
  }
  .adm-picker-header-button {
    .confirmText {
      font-weight: 400;
      font-size: 16px;
      color: #0081bb;
    }
    .cancelText {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #adafb4;
    }
  }
  .check_time {
    background-color: #f0f3f5;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
    min-height: 40vh;
    .header {
      display: flex;
      align-items: center;
      height: 48px;
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      border: 1px solid #f0f0f0;
      justify-content: space-between;
      padding: 0px 20px;
      background-color: #ffffff;
      .left {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 18px;
          height: 18px;
          transform: rotate(90deg);
        }
      }
      .name {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
    }
    .content {
      padding: 16px 12px;
      overflow-y: auto;
      max-height: 60vh;
      .doctor_card {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px 6px 6px 6px;
        width: 100%;
        box-sizing: border-box;
        .avatar {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .info {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          flex: 1;
          .name_box {
            display: flex;
            align-items: center;
            .name {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 500;
              font-size: 15px;
              color: #243752;
            }
            .posts {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
              margin-left: 6px;
            }
          }
          .unit1 {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .unit {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
            margin-top: 4px;
          }
          .profession {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
        }
      }
      .upgrade_line {
        margin-top: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .line {
          flex: 1;
          height: 1px;
          background-color: #d3d7dc;
        }
        .center {
          padding: 0px 10px;
          display: inline-flex;
          align-items: center;
          .img {
            width: 12px;
            height: 12px;
          }
          .text {
            font-weight: 500;
            font-size: 14px;
            color: #a7afba;
            margin-left: 6px;
          }
        }
      }
      .time_card {
        background: #ffffff;
        box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 20px 16px;
        margin-top: 16px;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #243752;
        }
        .time_list {
          background: #f7f7f7;
          border-radius: 12px 12px 12px 12px;
          padding: 16px;
          margin-top: 16px;
          .time_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
              width: 33.33%;
              font-weight: 400;
              font-size: 14px;
              color: #243752;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .time_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            & > div {
              width: 33.33%;
              font-weight: 400;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .time {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .week {
                font-weight: 500;
                font-size: 14px;
                color: #243752;
              }
              .day {
                font-weight: 400;
                font-size: 12px;
                color: #7c8797;
              }
            }
            .center,
            .right {
              display: flex;
              align-items: center;
              justify-content: center;
              .btn {
                width: 80px;
                height: 30px;
                background: #ffffff;
                border-radius: 46px 46px 46px 46px;
                font-weight: 400;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .active_btn {
                border: 1px solid #1778ff;
                color: #1778ff;
              }
              .disable_btn {
                border: 1px solid #a7afba;
                color: #a7afba;
              }
              .check_btn {
                // border: 1px solid #1778ff;
                color: #fff;
                background: #1778ff;
              }
            }
          }
        }
      }
    }
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 160px;
        height: 160px;
        margin-bottom: 16px;
      }
      .empty-text {
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
      }
    }
  }
}

@root-entry-name: default;