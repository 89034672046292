@import '../../style/vars.less';

.patient-header {
  height: 62px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  .patient-header_content {
    display: flex;
    flex-direction: column;
    .id_card {
      font-weight: 400;
      font-size: 12px;
      color: #7c8797;
      margin-left: 8px;
    }
  }
  .avatar img {
    width: 42px;
    height: 42px;
    background: #d0eded;
    border-radius: 50%;
    border: 1px solid #aadede;
  }
  .user_name_box {
    display: flex;
    align-items: center;
    flex: 1;
    .mixin_gap_row(8px);
    margin-left: 8px;
    .user_name {
      font-weight: 500;
      font-size: 18px;
      color: #243752;
    }
    .user_gender {
      font-weight: 400;
      font-size: 14px;
      color: #505f75;
    }
    .user_age {
      font-weight: 400;
      font-size: 14px;
      color: #505f75;
    }
  }
  .right {
    margin-left: auto;
  }
}
@root-entry-name: default;