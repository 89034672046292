/*
 类名变量，全局的 less 需文件导入用到
 */
/**
 * 颜色变量
 */
/**
 * 边距变量
 */
/**
 * 字号变量
 */
/**
 * 其他变量
 */
