.Empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .empty {
    width: 124px;
    height: 124px;
    margin-top: 16px;
  }
  .text {
    font-weight: 500;
    font-size: 14px;
    color: #7c8797;
    margin-top: 16px;
  }
}

@root-entry-name: default;