.index-container {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background: #ecf8f8;
  overflow: auto;
  position: relative;
  z-index: 10;

  .index-title {
    font-weight: normal;
    font-size: 20px;
    color: #2b313d;
    margin-bottom: 12px;
    font-family: AlibabaHealthFont20CN;
  }
  .header-bg {
    // position: absolute;
    .img-bg {
      width: 100%;
      height: 150px;
    }
  }
  .index-header {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    .index-logo {
      width: 216px;
      height: 88px;
    }
    .index-header-title {
      font-weight: normal;
      font-size: 36px;
      color: #ffffff;
      margin: 8px 0px;
    }
    .index-text {
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 22px;
    }
  }

  .content-item {
    padding: 0px 16px;
    box-sizing: border-box;
  }
  .service {
    width: 100%;
    transform: translateY(-32px);
    position: relative;
    z-index: 99;
    border-radius: 16px 16px 0px 0px;
    background: linear-gradient(180deg, #ffffff 30%, #ecf8f8 70%);
    padding: 16px;
    box-sizing: border-box;
    padding-bottom: 0px;
    .service-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .service-item {
        flex: 1;
        flex-grow: 1;
        flex-shrink: 0;
        border-radius: 8px;
        // height: 102px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .service-item + .service-item {
        margin-left: 12px;
      }
    }
    .service-item {
      flex: 1;
      // border: 1px red solid;
      height: 104px;
      border-radius: 8px;
    }
    .service-item + .service-item {
      margin-left: 12px;
    }
    .Swiper {
      margin-bottom: 12px;
    }
    .index-div {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 12px;
    }
  }
  //   height: 100%;
  .first {
    width: 100%;
    box-sizing: border-box;
    // padding: 24px 10px;
    padding-bottom: 0px;
    // position: relative;
    z-index: 10;
    // // background: linear-gradient(180deg, #EAEDEB 30%, #ffffff 0%);
    // // background: linear-gradient(180deg, #fff3f0 0%, #ffffff 10%);
    // box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    // border-radius: 16px;
    // border: 1px solid #ffffff;
    .activeRetract {
      height: 160px;
      overflow: hidden;
    }
    .grid-item {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        margin-bottom: 6px;
      }
      .item-name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #243752;
        text-align: center;
      }
    }
    .retract {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 16px;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #243752;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        .retract-text {
          margin-right: 4px;
        }
      }
    }
  }
  .second {
    width: 100%;
    padding: 24px 0px;
    height: 142px;
    display: flex;
    justify-content: space-between;
    .second-left {
      flex: 1;
      height: 100%;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .left-top {
        flex: 1;
        margin-bottom: 10px;
        border-radius: 6px;
        background-image: url(../../assets/housekeep_bg.png);
        background-size: 100% 100%;
        box-sizing: border-box;
      }
      .left-bottom {
        flex: 1;
        border-radius: 6px;
        background-image: url(../../assets/files_bg.png);
        background-size: 100% 100%;
        box-sizing: border-box;
      }
      .second-div {
        padding: 7px 12px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        .second-img {
          width: 50px;
          height: 50px;
        }
        .left-title {
          font-weight: bold;
          font-size: 16px;
          color: #ffffff;
          margin-top: 5px;
        }
      }
    }
    .second-rigth {
      flex: 1;
      height: 100%;
      border-radius: 6px;
      background-image: url(../../assets/record_bg.png);
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 12px;
      position: relative;
      .left-title {
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        margin-top: 5px;
        margin-bottom: 10px;
      }
      .btn {
        font-weight: 400;
        font-size: 12px;
        color: #f9646b;
        width: 60px;
        height: 20px;
        background: #ffffff;
        border-radius: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rigth-img {
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: 0px;
        right: 4px;
      }
    }
  }
  .third {
    width: 100%;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #243752;
    padding-left: 16px;
    box-sizing: border-box;
    margin-top: 6px;
    // .index-title {
    //   padding-left: 16px;
    // }
    .third-header {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      flex-wrap: nowrap;
      box-sizing: border-box;
      .header-item {
        padding: 12px 24px;
        font-weight: normal;
        font-size: 16px;
        color: #2b313d;
        border-radius: 10px;
        max-width: 160px;

        box-sizing: border-box;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        .item-text {
          width: 100%;
          white-space: nowrap;
          text-align: center;
          min-width: 80px;

          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
      .header-item + .header-item {
        margin-left: 4px;
      }
      .activeItem {
        background-color: #1dbcc0;
        color: #ffffff;
        font-weight: bold;
        background-image: url(../../assets//activeList.png);
        background-size: cover; /* 背景图片覆盖整个元素 */
        background-repeat: no-repeat; /* 背景图片不重复 */
        background-position: center; /* 背景图片居中 */
      }
    }
    .third-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 16px;
      box-sizing: border-box;
      min-height: 400px;
      .popular-item {
        width: 100%;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        border-radius: 12px;
        padding: 12px;
        margin-top: 12px;
        .popular-header {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .popular-header-left {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: bold;
            font-size: 14px;
            color: #243752;
            display: flex;
            align-items: center;

            .left-logo {
              width: 24px;
              height: 24px;
              border-radius: 21px;
              margin-right: 6px;
            }
          }
          .popular-header-rigth {
            display: flex;
            align-items: center;
            .rigth-name {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
            }
          }
        }
        .popular-content {
          width: 100%;
          .popular-content-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin-top: 24px;
            .popular-img {
              width: 60px;
              height: 60px;
              border-radius: 8px;
              margin-right: 12px;
              flex-shrink: 0;
            }
            .iocn {
              width: 20px;
              height: 20px;
              background: #ff8f50;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 14px;
            }
            .item-content {
              flex: 1;
              box-sizing: border-box;
              padding: 4px 0px;
              display: flex;
              flex-direction: column;
              font-family:
                PingFang SC,
                PingFang SC;
              .content-name {
                font-weight: bold;
                font-size: 14px;
                color: #243752;
                margin-bottom: 6px;
              }
              .content-text {
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #2b313d;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }
}

@root-entry-name: default;