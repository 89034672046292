@import '../../style/vars.less';

.IndicatorDetails-header {
  width: 100%;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(180, 178, 200, 0.25);
  display: flex;
  font-weight: 400;
  font-size: 14px;
  color: #7c8797;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0px 22px;
  padding-right: 0px;
  align-items: center;
  box-sizing: border-box;
  position: sticky;
  // z-index: 10;
  .left {
    flex: 1;
    height: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    padding-right: 8px;
  }
  .header-item {
    flex-shrink: 0;
    white-space: nowrap;
    max-width: 100px;
  }
  .activeItem {
    color: #1778ff;
  }
  .sign {
    position: relative;
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 12px;
      right: -6px;
      width: 6px;
      height: 6px;
      background: #e32525;
      border-radius: 50%;
    }
  }
  .header-item + .header-item {
    margin-left: 17px;
  }
  .rigth {
    padding-right: 22px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-weight: 400;
    font-size: 14px;
    color: #243752;
    padding-left: 8px;
    border-left: 1px solid #ebebeb;
    background-color: #ffffff;
    height: 15px;
    margin-left: 8px;
    .icon {
      margin-left: 2px;
    }
    .rigth-left {
      width: 20px;
      height: 100%;
      opacity: 0.1;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

@root-entry-name: default;