.invoice-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #f0f3f5;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 12px;
    .title {
      padding: 16px 8px;
      font-size: 14px;
      color: #7c8797;
    }
    .adm-list-body {
      border-radius: 15px;
      --placeholder-color: #a7afba;

      .adm-form-item-required-asterisk {
        display: none;
      }
      .adm-input-element {
        font-size: 14px;
        text-align: right;
        // --color: #243752;
        opacity: 1 !important;
        color: #0b0c0c !important;
      }
      .adm-list-item-disabled {
        .adm-input-element {
          font-size: 14px;
          text-align: right;
        }
      }
      .adm-form-item-label {
        font-size: 14px;
        color: #243752;
      }
      .adm-form-item-feedback-error {
        text-align: right;
      }
      .adm-list-item-content {
        --border-inner: none;
      }
      .adm-input-disabled {
        opacity: 1;
      }
    }
    .price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background-color: #fff;
      border-radius: 15px;
      box-sizing: border-box;
      margin: 16px 0px 0px 0px;
      .left {
        font-size: 14px;
        color: #243752;
        display: flex;
        .num {
          font-weight: 600;
          font-size: 16px;
          color: #eb5e3d;
          margin-right: 4px;
        }
      }
      .rigth {
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
      }
    }
    .footer {
      position: fixed;
      bottom: 38px;
      left: 0px;
      width: 100%;
      padding: 0px 20px;
      box-sizing: border-box;
    }
    .footerBtn {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      height: 42px;
      background: #eb5e3d;
      border-radius: 59px 59px 59px 59px;
      box-sizing: border-box;
    }
  }
}
.invoice-popup {
  border-radius: 16px 16px 0px 0px;
  .invoice-content {
    width: 100%;
    min-height: 391px;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      font-size: 16px;
      font-weight: bold;
    }
    .content {
      width: 100%;
      padding: 10px 12px;
      box-sizing: border-box;
      .prompt {
        font-weight: 400;
        font-size: 12px;
        color: #eb5e3d;
      }
      .time {
        width: 100%;
        padding: 12px 16px;
        margin-bottom: 24px;
      }
      .content-item {
        padding: 12px 16px;
        height: fit-content;
        .item {
          display: flex;
          .title {
            width: 90px;
            font-weight: 400;
            font-size: 14px;
            color: #7c8797;
            margin-right: 16px;
            flex-wrap: nowrap;
          }
          .text {
            font-weight: 400;
            font-size: 14px;
            color: #243752;
          }
        }
        .item + .prompt {
          margin-top: 6px;
        }
      }
      .footer {
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
      }
      .footerBtn {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        height: 42px;
        background: #eb5e3d;
        border-radius: 59px 59px 59px 59px;
        box-sizing: border-box;
      }
    }
  }
}

@root-entry-name: default;