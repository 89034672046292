@import '../../style/vars.less';
.order_list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .tabs {
    .adm-tabs-header {
      border-bottom: none;
    }
    .adm-tabs-tab-line {
      height: 3px;
      background-color: @primary-color;
    }
    .adm-tabs-tab {
      font-weight: 400;
      font-size: 16px;
      color: #505f75;
    }
    .adm-tabs-tab-active {
      font-weight: 500;
      font-size: 16px;
      color: @primary-color;
    }
    .adm-tabs-content {
      padding: 0px;
    }
  }
}

@root-entry-name: default;