.medical {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &_title {
    width: 100vw;
    z-index: 1;
    // height: 15.5vh;
    // height: 15%;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
    &_user {
      margin: 5px 10px;
      display: flex;
      align-items: center;
      img {
        width: 50px;
      }
      h3 {
        font-weight: 500;
        font-size: 16px;
        margin: 0 12px;
      }
      .i {
        margin: 0 2px;
      }
      p {
        color: #ccc;
      }
    }
    .divider {
      margin: 5px 0;
    }
    &_btn {
      .active {
        color: #3c8be5;
        background-color: #e7f2ff;
      }
      Button {
        padding: 5px 25px !important;
        margin: 5px;
        background-color: #f1f3f5;
      }
    }
  }
  &_content {
    width: 100%;
    height: 100%;
    // height: 85%;
    display: flex;
    align-items: center;
    flex-shrink: 1;
    &_left {
      width: 30%;
      height: 100%;
      overflow-y: auto;
      background-color: #f5f7fa;
      ul {
        box-sizing: border-box;
        list-style: none;
        li {
          padding: 10px;
          p:first-child {
            color: #8c8c8c;
            font-size: 14px;
          }
          //   height: 40px;
          &.active {
            p {
              color: #fff;
            }
            background-color: #0d70de;
          }
        }
      }
    }
    &_right {
      height: 100%;
      flex: 1;
      overflow-y: auto;
      > div {
        margin: 0 16px;
        height: 100%;
        // width: 100%;
        .divider {
          margin: 20px 0;
        }
        .title {
          font-weight: bold;
        }
        .height_weight {
          display: flex;
          div {
            display: flex;
            p {
              margin-right: 10px;
            }
            &:nth-child(1) {
              margin-right: 20px;
            }
          }
        }
        .nullText {
          color: #cecece;
        }
        .adm-image-tip {
          margin: 10px 0;
        }
      }
      &_card {
        .indicators {
          width: 100%;
          // min-height: 356px;
          background: #e7f2ff;
          box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
          border-radius: 12px;
          padding: 8px 16px;
          margin: 16px 0;
          box-sizing: border-box;
          .indicators-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            .header-title {
              font-weight: bold;
              font-size: 16px;
              color: #243752;
            }
            .header-rigth {
              font-weight: 400;
              font-size: 12px;
              color: #fe977a;
              padding: 0 5px;
              background-color: #ffeee1;
              display: flex;
              align-items: center;
              .text {
                margin-right: 2px;
              }
              .antd-mobile-icon {
                margin-bottom: 2px;
              }
            }
          }

          .indicators-title {
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .indicators-abnormol {
            .abnormol-content {
              width: 100%;
              border: 1px solid #ffe0d9;
              border-radius: 6px 6px 6px 6px;
              padding: 0px 12px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              background: #fff8f6;
              .abnormol-item {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                .item-title {
                  width: 84px;
                  white-space: nowrap;
                  font-size: 14px;
                  color: #243752;
                }
                .item-num {
                  font-size: 14px;
                  color: #1dbcc0;
                }
                .abnormol-state {
                  font-size: 14px;
                  color: #eb5e3d;
                }
              }
              .abnormol-item + .abnormol-item {
                border-top: 1px solid #e6ebe9;
              }
            }
          }
          .indicators-adnormol-myHealth {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              width: 25%;
              text-align: center;
            }
          }
          .normol {
            border: 1px solid #cce7e7 !important;
            background: #f5faf8 !important;
            .normol-text {
              color: #00c242 !important;
            }
          }
        }
        &_O {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          p {
            &:nth-child(2) {
              margin: 0 5px;
            }
          }
        }
        &_btn {
          display: flex;
          color: #2d82e3;
          align-items: center;
          margin: 10px 0 5px 0;
          .icon {
            color: #2d82e3;
          }
        }
      }
    }
  }
}

@root-entry-name: default;