@import '../../../../style/vars.less';
.service_popup1 {
  .service_popup_content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 40vh;
    max-height: 66vh;
    .header {
      background: #ffffff;
      padding-top: 20px;
      padding-bottom: 13px;
      border-radius: 16px 16px 0px 0px;

      .headeer_top {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .title {
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #243752;
        }
        .close_box {
          position: absolute;
          right: 17px;
          padding: 5px;
        }
        .close {
          width: 21px;
          height: 21px;
        }
      }
      .select_box {
        margin-top: 26px;
        display: flex;
        justify-content: center;
        // gap: 43px;
        .mixin_gap_row(43px);

        .select_item {
          min-width: 122px;
          height: 34px;
          background: #f5f5f5;
          border-radius: 18px 18px 18px 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 24px;
          box-sizing: border-box;
          // gap: 7px;
          .select_name {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
          }
          .select_icon {
            width: 10px;
            margin-left: 7px;
          }
          .select_icon_active {
            transform: rotate(180deg);
          }
        }
      }
    }
    .service_list {
      background-color: #f8f8f8;
      padding: 20px 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      // gap: 16px;
      .mixin_gap_column(16px);
      overflow: scroll;
      flex: 1;
      .empty {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 160px;
          height: 160px;
          margin-bottom: 16px;
        }
        .empty-text {
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
        }
      }
      .service_item {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
        border-radius: 6px 6px 6px 6px;
        width: 100%;
        box-sizing: border-box;
        .avatar {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          // background-color: #ff6023;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .info {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          flex: 1;
          .name_box {
            display: flex;
            align-items: center;
            .name {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 500;
              font-size: 15px;
              color: #243752;
            }
            .posts {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
              margin-left: 6px;
            }
          }
          .unit1 {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .unit {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
            margin-top: 4px;
          }
          .profession {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
        }
        .price {
          margin-left: auto;
          color: #ff6023;
          display: flex;
          align-items: end;
          line-height: 1;
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
    .topage_btn {
      width: 335px;
      height: 42px;
      background: #eb5e3d;
      border-radius: 59px 59px 59px 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      margin: 10px auto;
    }
  }
}

@root-entry-name: default;