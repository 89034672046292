@import '../../style/vars.less';

.Indicator-item {
  display: flex;
  flex-direction: column;
  .rule {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 6px 6px 6px 6px;
    padding: 12px;
    .item-content {
      display: flex;
      width: 100%;
      &:not(:first-child) {
        margin-top: 12px;
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .text {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        line-height: 22px;
        word-wrap: break-word;
        word-break: break-all;
      }
      .url {
        color: #1778ff;
      }
    }
  }
  .date {
    font-weight: 400;
    font-size: 12px;
    color: #7c8797;
    line-height: 1;
    padding: 6px;
  }
  .imgContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .img-box {
      min-width: 30%;
      max-width: 34%;
      flex: 1;
      max-height: 90px;
      padding: 6px;
      .imgContent-item {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: cover;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #d6d6d6;
      }
    }
  }
  .record {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 12px;
    padding: 10px 12px;
    box-sizing: border-box;
    margin-top: 12px;
    .record-header {
      padding-bottom: 12px;
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rigth {
        width: 112px;
        height: 30px;
        // background: #1778FF;
        display: flex;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #1778ff;
        box-sizing: border-box;
        color: #1778ff;
        overflow: hidden;
        .btn {
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 14px;
          // color: #ffffff;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .activeBtn {
          color: #ffffff;
          background-color: #1778ff;
        }
        .activeBtn1 {
          color: #ffffff;
          background-color: #1778ff;
        }
      }
    }
    .chart {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .indicatorChart {
        width: 100%;
        height: 238px;
      }
      .tag {
        display: flex;
        align-items: center;
        margin-left: 35px;
        img {
          width: 12px;
          height: 12px;
        }
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .charts {
        position: absolute;
        width: 100%;
        height: 238px;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        img {
          width: 50%;
          margin-top: 20px;
        }
        span {
          padding: 20px;
          font-size: 14px;
        }
      }
    }
  }
  .noReadReport {
    padding: 0px;
    .report_table {
      padding: 2px 4px;
    }
  }
  .abnorma {
    .report_table {
      padding: 0px;
      th:first-child,
      td:first-child {
        padding-left: 0px;
      }
      th:last-child,
      td:last-child {
        padding-right: 0px;
      }
    }
  }
}

@root-entry-name: default;