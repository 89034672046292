@import '../../style/vars.less';

.user_info_card {
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
  border-radius: 12px 12px 12px 12px;
  padding: 10px 12px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .avatar img {
    width: 42px;
    height: 42px;
    background: #d0eded;
    border-radius: 50%;
    border: 1px solid #aadede;
  }
  .user_name_box {
    display: flex;
    align-items: center;
    .mixin_gap_row(8px);
    margin-left: 8px;
    .user_name {
      font-weight: 500;
      font-size: 18px;
      color: #243752;
    }
    .user_gender {
      font-weight: 400;
      font-size: 14px;
      color: #505f75;
    }
    .user_age {
      font-weight: 400;
      font-size: 14px;
      color: #505f75;
    }
  }
  .user_right {
    font-weight: 400;
    font-size: 14px;
    color: #7c8797;
    margin-left: auto;
    .user_right_text {
      max-width: 36vw;
    }
  }
}
@root-entry-name: default;