.interview-container {
  width: 100%;
  height: calc(100vh - 0px);
  background: #fff;
  box-sizing: border-box;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #f0f3f5;
    --active-line-color: #1778ff;
    --active-title-color: #1778ff;
    --content-padding: 0px;
    .adm-tabs-header {
      background: #fff;
    }
    .adm-tabs-content {
      flex: 1;
      display: flex;
      .tab1 {
        width: 100%;
        height: 100%;
        padding: 16px 12px;
        box-sizing: border-box;
        .empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 160px;
            height: 160px;
            margin-bottom: 16px;
          }
          .empty-text {
            font-weight: 400;
            font-size: 14px;
            color: #7c8797;
          }
        }
      }
      .item {
        width: 100%;
        min-height: 116px;
        background: #ffffff;
        box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        border-radius: 12px;
        box-sizing: border-box;
        padding: 16px;
        .item-header {
          width: 100%;
          margin-bottom: 12px;
          font-weight: bold;
          font-size: 16px;
          color: #243752;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .age {
            font-weight: 400;
            font-size: 14px;
            color: #243752;
            margin-left: 12px;
          }
          .left {
            display: flex;
          }
          .rigth {
            .AUDIT_SUCCESS {
              font-weight: 400;
              font-size: 14px;
              color: #1778ff;
            }
            .CANCEL, .OUT_TIME_CANCEL {
              font-weight: 400;
              font-size: 14px;
              color: #7c8797;
            }
          }
        }
        .red {
          color: #e32525 !important;
        }
        .item-content {
          width: 100%;
          padding: 12px 0px;
          border-top: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
          margin-bottom: 12px;
          .content-item {
            width: 100%;
            display: flex;
            .item-title {
              width: 80px;
              font-weight: 400;
              font-size: 13px;
              color: #7c8797;
              flex-shrink: 0;
              // margin-right: 24px;
            }
            .item-text {
              font-weight: 400;
              font-size: 13px;
              color: #243752;
            }
          }
          .content-item + .content-item {
            margin-top: 12px;
          }
        }
        .record {
          border-bottom: none;
        }
        .item-bottom {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .btn {
            width: 86px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #1778ff;
            border-radius: 59px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            box-sizing: border-box;
          }
          .onlinBtn {
            width: 86px;
            height: 30px;
            background: rgba(23, 120, 255, 0.05);
            border-radius: 59px;
            border: 1px solid #1778ff;
            font-weight: 400;
            font-size: 14px;
            color: #1778ff;
            box-sizing: border-box;
          }
          .btn + .btn {
            margin-left: 16px;
          }
        }
      }
      .item + .item {
        margin-top: 16px;
      }
    }
  }
}
.interview-popup {
  border-radius: 16px 16px 0px 0px;
  .popup-content {
    width: 100%;
    height: 288px;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    .popup-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: bold;
        font-size: 16px;
        color: #243752;
      }
      .btn {
        font-weight: 400;
        font-size: 16px;
        color: #adafb4;
      }
      .determine {
        color: #1778ff;
      }
    }
    .content {
      width: 100%;
      padding: 16px 0px;
      box-sizing: border-box;
      .adm-form {
        --border-inner: none;
        --border-top: none;
        --border-bottom: none;
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
        .adm-list-item-content-main {
          padding: 0px !important;
        }
        .adm-list {
          --padding-right: 0px;
          --padding-left: 0px;

          // padding: 0px !important;
          // padding-left: 0px !important;
          .adm-text-area-element {
            background: #f7f7f7;
            border-radius: 6px 6px 6px 6px;
            padding: 10px;
            --font-size: 13px;
            --placeholder-color: #a7afba;
          }
        }
      }
    }
  }
}

@root-entry-name: default;