.Report-box {
  width: 100%;
  min-height: 100%;
  background: #f0f3f5;
  padding: 16px 12px;
  box-sizing: border-box;
  .Report-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #a7afba;
    margin-bottom: 16px;
  }
  .first {
    width: 100%;
    min-height: 340px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 0px 16px;
    box-sizing: border-box;
    margin-bottom: 18px;
    .first-item {
      width: 100%;
      padding: 17px 0px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        width: 56px;
        font-weight: 500;
        font-size: 14px;
        color: #243752;
      }
      .text {
        flex: 1;
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
        text-align: right;
      }
    }
    .first-item + .first-item {
      border-top: 1px solid #dce4e5;
    }
  }
  .second {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 0px 16px;
    margin-bottom: 24px;
    .second-header {
      width: 100%;
      padding: 10px 0px;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 12px;
      color: #7c8797;
      display: flex;
    }
    .item1 {
      width: 45%;
      flex-shrink: 0;
    }
    .item2 {
      width: 15%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
    .item3 {
      width: 20%;
      flex-shrink: 0;
    }
    .item4 {
      width: 20%;
      flex-shrink: 0;
    }
    .second-content {
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      color: #243752;
      .second-item {
        width: 100%;
        display: flex;
        border-top: 1px solid #f5f5f5;
        padding: 14px 0px;
        .tallImg {
          width: 14px;
          height: 14px;
        }
      }
      //   .second-item + .second-item {
      //     border-top: 1px solid #f5f5f5;
      //   }
    }
  }
  .prompt {
    font-weight: 400;
    font-size: 14px;
    color: #7c8797;
  }
  .adm-swiper-indicator {
    display: none;
  }
}

@root-entry-name: default;