@import '../../style/vars.less';
.PatientInformation-container {
  width: 100%;
  min-height: 100%;
  padding-bottom: 20px;
  background: #f0f3f5;

  .header-box {
    width: 100%;
    height: 122px;
    padding: 16px 12px 16px 20px;
    box-sizing: border-box;
    background: linear-gradient(
      180deg,
      rgba(87, 150, 253, 0.2) 0%,
      rgba(87, 150, 253, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      margin-right: 16px;
    }
    .header-center {
      width: 150px;
      flex-shrink: 0;
      .center-header {
        display: flex;
        margin-bottom: 8px;
        .name {
          font-weight: 500;
          font-size: 16px;
          color: #243752;
        }
        .icon {
          margin: 0px 7px;
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
          display: flex;
          align-items: center;
        }
        .age {
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
          display: flex;
          align-items: center;
        }
      }
      .center-item {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
        margin-bottom: 6px;
      }
    }
    .header-rigth {
      width: 110px;
      height: 100%;
      padding-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      // align-items: flex-end;
      flex-direction: column;
      align-items: flex-end;
      justify-items: flex-end;
      justify-content: flex-end;
      // align-content: flex-end;
      .bottom {
        // .mixin_gap_row(6px);
        display: flex;
        // align-items: flex-end;
        align-content: flex-end;
        justify-content: flex-end;
        // justify-content: space-between;
        //   background: linear-gradient(90deg, #66a6ff 0%, #066af5 100%);
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 12px;
        color: #066af5;
      }
      .top {
        width: 50px;
        height: 20px;
        background: linear-gradient(90deg, #66a6ff 0%, #066af5 100%);
        border-radius: 27px 27px 27px 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        margin-bottom: 6px;
        .top-icon {
          width: 10px;
          height: 10px;
          margin-right: 2px;
        }
      }
      .tag-item {
        width: fit-content;
        height: 14px;
        padding: 0px 4px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 10px;
        color: #1778ff;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #1778ff;
        margin-left: 6px;
      }
    }
  }
  .content {
    width: 100%;
    padding: 0px 12px;
    box-sizing: border-box;
    .box {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      border-radius: 12px 12px 12px 12px;
    }
    .frstBox {
      width: 100%;
      min-height: 222px;
      padding: 16px;
      box-sizing: border-box;
      transform: translateY(-16px);
      .tag-box {
        display: flex;
        padding: 16px 0px;
        .mixin_gap_row(10px);
        .tag {
          padding: 5px 10px;
          border-radius: 22px;
          background: rgba(255, 126, 0, 0.1);
          font-weight: 500;
          font-size: 13px;
          color: #ff7e00;
        }
      }

      .tag1-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 6px;
        .adm-grid {
          width: 100%;
        }
        // .mixin_gap_row(10px);
        .tag1 {
          // width: 154px;
          // flex: 1;
          height: 32px;
          background: rgba(84, 152, 251, 0.1);
          border-radius: 6px 6px 6px 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 10px;
          box-sizing: border-box;
          margin-top: 10px;

          .left {
            display: flex;
            align-items: center;
            .title {
              font-weight: 500;
              font-size: 13px;
              color: #505f75;
              margin-left: 7px;
            }
            .icon {
              width: 16px;
              height: 16px;
              .img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .key {
            font-weight: 500;
            font-size: 13px;
            color: #1778ff;
          }
        }
      }
    }
    .charts-box {
      width: 100%;
      height: 300px;
      border-radius: 12px;
      padding: 16px 16px;
      box-sizing: border-box;
      .charts {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }
    .third-box {
      width: 100%;
      min-height: 240px;
      background: #ffffff;
      box-shadow: 0px -2px 4px 0px rgba(108, 92, 66, 0.04);
      border-radius: 12px 12px 12px 12px;
      display: flex;
      flex-direction: column;
      position: relative;
      .third-tab {
        width: 100%;
        height: 50px;
        background-color: #d1e2f4;
        border-radius: 12px 12px 0px 0px;
        display: flex;
        align-items: center;
        .tab-item {
          width: 117px;
          height: 40px;
          color: #243752;
          font-weight: bold;
          font-size: 14px;
          color: #243752;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
        .activeTab {
          color: #1778ff;
          z-index: 1;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 16px;
            height: 4px;
            background: #1778ff;
            border-radius: 16px 16px 16px 16px;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .third-line-bg {
        width: 100%;
        height: 10px;
        background-color: #d1e2f4;
      }
      .third-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
      .third-content {
        position: relative;
        z-index: 1;
        flex: 1;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // align-content: center;
        // flex-wrap: wrap;
        transform: translateY(-10px);
        background-color: #fff;
        border-radius: 12px 12px 0px 0px;
        padding: 12px;
        box-sizing: border-box;
        .third-content-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .left {
            font-weight: 400;
            font-size: 14px;
            color: #243752;
            display: flex;
            .text {
              color: #1778ff;
              margin-right: 20px;
            }
          }
          .add {
            font-weight: 400;
            font-size: 12px;
            color: #a7afba;
          }
        }
        .tag-box {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
        }
        .third-tag {
          width: fit-content;
          height: 32px;
          background: rgba(228, 101, 239, 0.1);
          border-radius: 6px 6px 6px 6px;
          display: flex;
          align-items: center;
          padding: 0px 8px;
          .span {
            margin-left: 10px;
          }
          .tag-span {
            font-weight: 500;
            font-size: 13px;
            color: #505f75;
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #dce4e5;
      margin: 20px 0px;
    }
    .fifth-box {
      margin-top: 24px;
      background: #f0f3f5;
      .fifth-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .header-item {
          flex: 1;
          height: 52px;
          background: linear-gradient(90deg, #66a6ff 0%, #066af5 100%);
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
        .item1 {
          background: linear-gradient(90deg, #66a6ff 0%, #066af5 100%);
        }
        .item2 {
          background: linear-gradient(270deg, #0aa1f6 0%, #5ecfff 100%);
        }
        .header-item + .header-item {
          margin-left: 10px;
        }
      }
      .fifth-content {
        padding-top: 16px;
        // background: #f0f3f5;
        .mine-col {
          margin-bottom: 16px;
        }
        .indicators {
          width: 100%;
          // min-height: 356px;
          background: #ffffff;
          box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
          border-radius: 12px;
          padding: 16px;
          box-sizing: border-box;
          .indicators-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            .header-title {
              font-weight: bold;
              font-size: 16px;
              color: #243752;
            }
            .header-rigth {
              font-weight: 400;
              font-size: 12px;
              color: #a7afba;
              display: flex;
              align-items: center;
              .text {
                margin-right: 2px;
              }
              .antd-mobile-icon {
                margin-bottom: 2px;
              }
            }
          }

          .indicators-title {
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .indicators-abnormol {
            .abnormol-content {
              width: 100%;
              border: 1px solid #ffe0d9;
              border-radius: 6px 6px 6px 6px;
              padding: 0px 12px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              background: #fff8f6;
              .abnormol-item {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                .item-title {
                  width: 84px;
                  white-space: nowrap;
                  font-size: 14px;
                  color: #243752;
                }
                .item-num {
                  font-size: 14px;
                  color: #1dbcc0;
                }
                .abnormol-state {
                  font-size: 14px;
                  color: #eb5e3d;
                }
              }
              .abnormol-item + .abnormol-item {
                border-top: 1px solid #e6ebe9;
              }
            }
          }
          .normol {
            border: 1px solid #cce7e7 !important;
            background: #f5faf8 !important;
            .normol-text {
              color: #00c242 !important;
            }
          }
        }
      }
    }
  }
}
.header-title {
  font-weight: 600;
  font-size: 16px;
  color: #243752;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 4px;
    height: 16px;
    border-radius: 2px;
    margin-right: 6px;
  }
  .add {
    font-weight: 400;
    font-size: 12px;
    color: #a7afba;
  }
}

@root-entry-name: default;