@import '../../style/vars.less';

.service-details-container {
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 98px;
  box-sizing: border-box;
  background-color: #f0f3f5;
  .details-img {
    width: 100%;
    // height: 200vh;
    // border: 1px red solid;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .header-img {
      width: 100%;
      height: 180px;
    }
    .details {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 12px;
    }
    .service-details {
      width: 100%;
      padding: 16px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      border-radius: 12px;
      .header-title {
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        color: #243752;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
      }
      .text {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        color: #505f75;
        margin-bottom: 12px;
        .title {
          color: #505f75;
        }
      }
      .num {
        width: 100%;
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #eb5e3d;
        .title {
          color: #505f75;
        }
        // margin-bottom: 12px;
      }
    }
  }
  .evaluate {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 16px;
    width: calc(100% - 24px);
    margin: auto;
    box-sizing: border-box;
    .evaluate_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .evaluate_header_left {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .evaluate_header_right {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
    }
    .evaluate_content {
      .appraise_card {
        margin-top: 16px;
        .appraise_card_header {
          display: flex;
          .avatar {
            img {
              width: 32px;
              height: 32px;
              border-radius: 21px 21px 21px 21px;
            }
          }
          .user {
            margin-left: 10px;
            .name {
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
            }
            .star {
              margin-top: 5px;
            }
          }
          .date {
            font-weight: 400;
            font-size: 12px;
            color: #a7afba;
            margin-left: auto;
          }
        }
        .appraise_card_content {
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          margin-left: 42px;
          margin-top: 10px;
          .Ellipsis {
            --adm-color-primary: #a7afba;
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    min-height: 62px;
    padding: 14px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 99;
    background-color: #ffffff;
    padding-bottom: 30px;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 20px;
      .text {
        font-weight: 400;
        font-size: 12px;
        color: #505f75;
      }
      .icon {
        width: 26px;
        height: 26px;
      }
    }
    .rigth {
      cursor: pointer;
      width: 149px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary-color;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

@root-entry-name: default;