@import '../../style/vars.less';
@primary-color: #1778ff;

.health_care_service_details {
  padding-bottom: 100px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  .online_consultation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 32px;
    .title {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
    }
    .tit_exita {
      color: #1778ff;
    }
  }
  .card_list {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    width: calc(100% - 40px);
    margin: 24px auto 0px;
    .list_item {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0px 16px;
      .icon {
        width: 20px;
        height: 20px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #243752;
        margin-left: 8px;
      }
      .right {
        margin-left: auto;
      }
    }
  }
  .card {
    background: #ffffff;
    padding: 20px;
    line-height: 1;
    width: 100%;
    border-radius: 0px 0px 16px 16px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    box-shadow: 0px 2px 4px 0px rgba(32, 56, 101, 0.1);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 92px;
      background: linear-gradient(90deg, #577af8 0%, #4f8ef5 100%);
      border-radius: 6px 6px 6px 6px;
      position: relative;
      padding: 20px 16px;
      z-index: 1;
      box-sizing: border-box;
      .left {
        .title {
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
        }
        .info {
          margin-top: 16px;
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
        }
      }
      .price {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        .unit {
          font-size: 14px;
        }
      }
      .bg {
        position: absolute;
        width: 88%;
        height: calc(100% - 6px);
        top: 12px;
        margin: auto;
        left: 0;
        right: 0;
        background: linear-gradient(90deg, #577af8 0%, #4f8ef5 100%);
        border-radius: 6px 6px 6px 6px;
        opacity: 0.4;
        filter: blur(12px);
        z-index: -1;
      }
    }
    .info_box {
      margin-top: 26px;
      .online_consultation {
        padding: 0px;
        margin-top: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center;
          .line {
            margin: 0px 4px;
          }
          .state {
            font-weight: 400;
            font-size: 14px;
          }
          .state1 {
            color: @primary-color;
          }
          .state2 {
            color: #a7afba;
          }
        }
      }
      .infos {
        margin-top: 12px;
        display: flex;
        .label {
          font-weight: 400;
          font-size: 13px;
          color: #7c8797;
          min-width: 65px;
        }
        .value {
          font-weight: 400;
          font-size: 13px;
          color: #243752;
          margin-left: 24px;
        }
        .label1 {
          font-weight: 400;
          font-size: 13px;
          color: @primary-color;
        }
        .value1 {
          color: @primary-color;
        }
      }
    }
  }

  .bg_card_card {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 12px;
    width: calc(100% - 40px);
    margin: 24px auto 0px;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    .card_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 6px;
      .mixin_gap_column(6px);
      padding: 12px;
      box-sizing: border-box;
      line-height: 1;
      .icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;
        }
      }
      .name {
        line-height: 16px;
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .total_num {
        font-weight: 400;
        font-size: 10px;
        color: #7c8797;
      }
      .remainder_num {
        font-weight: 400;
        font-size: 10px;
        color: #1778ff;
      }
    }
  }
  .upgrade_line {
    margin: 32px auto 0px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw - 40px);
    box-sizing: border-box;

    .line {
      flex: 1;
      height: 1px;
      background-color: #d3d7dc;
    }
    .center {
      padding: 0px 10px;
      display: inline-flex;
      align-items: center;
      .img {
        width: 12px;
        height: 12px;
      }
      .text {
        font-weight: 500;
        font-size: 14px;
        color: #a7afba;
        margin-left: 6px;
      }
    }
  }
  .appraise_card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 16px;
    margin: 16px auto 0px;
    width: calc(100vw - 40px);
    box-sizing: border-box;
    .appraise_card_header {
      display: flex;
      .avatar {
        img {
          width: 32px;
          height: 32px;
          border-radius: 21px 21px 21px 21px;
        }
      }
      .user {
        margin-left: 10px;
        .name {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .star {
          margin-top: 5px;
        }
      }
      .date {
        font-weight: 400;
        font-size: 12px;
        color: #a7afba;
        margin-left: auto;
      }
    }
    .appraise_card_content {
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      margin-left: 42px;
      margin-top: 10px;
      .Ellipsis {
        --adm-color-primary: #a7afba;
      }
    }
  }
  .pay_box {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // height: 83px;
    background: #ffffff;
    padding: 6px 20px;
    line-height: 1;
    .left {
      display: flex;
      flex-direction: column;
      // gap: 5px;
      .mixin_gap_column(5px);
      .lable {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .price {
        .num {
          font-weight: 600;
          font-size: 20px;
          color: @primary-color;
        }
        .unit {
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          margin-left: 2px;
        }
      }
    }
    .pay_btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 149px;
      height: 38px;
      background: @primary-color;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

@root-entry-name: default;