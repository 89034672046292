.EllipsisDom {
  position: relative;
  .ellipsis_btn {
    background-color: #fff;
    z-index: 2;
    font-weight: 400;
    font-size: 12px;
    color: #a7afba;
  }
  .ellipsis_btn_expanded {
    position: absolute;
    bottom: 0px;
    right: 12px;
    padding-left: 4px;
  }
}

@root-entry-name: default;