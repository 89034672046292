.service-list-container {
  width: 100%;
  height: calc(100vh - 0px);
  background: #f0f3f5;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .service-header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .header-logo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
      // border: 1px red solid;
    }
    .header-text {
      font-weight: bold;
      font-size: 18px;
      color: #243752;
    }
  }
  .service-list-content {
    width: 100%;
    .service-item {
      width: 100%;
      height: 84px;
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 12px;
      .item-logo {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        // margin-left: 12px;
        // border: 1px red solid;
        flex-shrink: 0;
      }
      .item-content {
        flex: 1;
        box-sizing: border-box;
        padding: 4px 0px;
        margin-left: 12px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        .item-name {
          font-weight: bold;
          font-size: 14px;
          color: #4073b8;
          margin-bottom: 6px;
        }
        .item-text {
          flex: 1;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          //   -webkit-line-clamp: 2;
          //   text-overflow: ellipsis;
          //   white-space: ;
        }
      }
    }
    .service-item + .service-item {
      margin-top: 16px;
    }
  }
  .service-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    position: fixed;
    bottom: 20px;
    left: 0px;
    // background-color: #f0f3f5;
    background-color: transparent;

    .service-btn {
      width: 168px;
      height: 36px;
      background: #f0775b;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      border-radius: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      .btn-name {
        margin: 0px 10px;
      }
    }
  }
}

@root-entry-name: default;