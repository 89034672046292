.Guidance-contaniner {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  padding: 0px 12px;
  position: relative;
  padding-bottom: 80px;
  box-sizing: border-box;
  padding-top: 16px;
  overflow-y: auto;

  .popup-bottom {
    width: 100%;
    height: 62px;
    background: #ffffff;
    box-shadow: 0px -2px 6px 0px rgba(108, 134, 181, 0.1);
    border-radius: 36px 36px 0px 0px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 6px;
    box-sizing: border-box;
    .icon {
      width: 18px;
      height: 18px;
      transform: rotate(180deg);
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
    }
  }
}
.GuidanceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  .time {
    font-weight: 400;
    font-size: 13px;
    color: #7f8284;
    margin-bottom: 12px;
  }
  .GuidanceItem-content {
    width: 100%;
    padding: 16px;
    background: #ffffff;
    border-radius: 12px;
    box-sizing: border-box;
    .GuidanceItem-header {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .header-img {
        width: 42px;
        height: 42px;
        margin-right: 8px;
        border-radius: 50%;
        // border: 1px solid red;
      }
      .header-name {
        font-weight: 400;
        font-size: 14px;
        color: #505f75;
      }
    }
    .GuidanceItem-content-text {
      font-weight: 400;
      font-size: 16px;
      color: #243752;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
.GuidanceItem + .GuidanceItem {
  margin-top: 16px;
}
.seek-container {
  width: 100%;
  min-height: 200px;
  max-height: 100vh;
  background: #ffffff;
  box-shadow: 0px -2px 6px 0px rgba(108, 134, 181, 0.1);
  border-radius: 36px 36px 0px 0px;
  padding: 0px 30px 30px 30px;
  overflow-y: auto;
  .seek {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 6px;
    box-sizing: border-box;
    .icon {
      width: 18px;
      height: 18px;
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
    }
  }
  .seek-content {
    margin-top: 16px;
    .services-item {
      width: 96px;
      height: 160px;
      background: #f7f7f7;
      border-radius: 8px 8px 8px 8px;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .item-img {
        width: 42px;
        height: 42px;
        margin-bottom: 6px;
      }
      .total_num {
        font-weight: 400;
        font-size: 10px;
        color: #7c8797;
        margin-bottom: 6px;
      }
      .remainder_num {
        font-weight: 400;
        font-size: 10px;
        color: #1778ff;
      }
      .name {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        margin-bottom: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: center;
      }
    }
  }
}

@root-entry-name: default;