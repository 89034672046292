@import '../../style/vars.less';

.cases_detail {
  width: 100%;
  min-height: calc(100vh - 43px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .empty_content {
    height: 500px;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    --active-line-color: #1778ff;
    --active-title-color: #1778ff;
    --content-padding: 0px;
    .adm-tabs-tab {
      font-weight: 400;
    }
    .adm-tabs-tab-active {
      font-weight: 500 !important;
    }
    .adm-tabs-header {
      background: #fff;
    }
    .adm-tabs-content {
      flex: 1;
      display: flex;
      .user_info {
        height: 62px;
        background: #ffffff;
        box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 10px 12px;
        margin-top: 16px;
        display: flex;
        align-items: center;
        .avatar img {
          width: 42px;
          height: 42px;
          background: #d0eded;
          border-radius: 50%;
          border: 1px solid #aadede;
        }
        .user_name_box {
          display: flex;
          align-items: center;
          .mixin_gap_row(8px);
          margin-left: 8px;
          .user_name {
            font-weight: 500;
            font-size: 18px;
            color: #243752;
          }
          .user_gender {
            font-weight: 400;
            font-size: 14px;
            color: #505f75;
          }
          .user_age {
            font-weight: 400;
            font-size: 14px;
            color: #505f75;
          }
        }
        .user_right {
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
          margin-left: auto;
          .user_right_text {
            max-width: 36vw;
          }
        }
      }

      .d_card {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 12px;
        margin-top: 16px;
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #243752;
        }
        .d_card_list {
          margin-top: 24px;
          display: flex;
          align-items: center;
          .mixin_gap_row(9px);
          flex-wrap: nowrap;
          .d_card_item {
            // min-width: 75px;
            flex: 1;
            max-width: calc(25% - 7px);
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #ebebeb;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .mixin_gap_column(4px);
            position: relative;
            padding: 6px;
            .d_name {
              font-weight: 400;
              font-size: 13px;
              color: #243752;
            }
            .d_num {
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
            }
            .card_img {
              height: 19px;
            }
          }
          .active {
            background: rgba(23, 120, 255, 0.1);
            border: 1px solid #1778ff;
            // .d_name {
            //   color: #ffffff;
            // }
            // .d_num {
            //   color: #ffffff;
            // }
            &::after {
              display: block;
              content: ' ';
              background-image: url(../../assets/Slice56.png);
              width: 25px;
              height: 12px;
              position: absolute;
              bottom: -14px;
              left: 0;
              right: 0;
              margin: auto;
              background-size: 100%;
              z-index: 9;
            }
          }
        }
        .item_info {
          background: #ffffff;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #ebebeb;
          padding: 12px;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          .quest_item {
            border-radius: 6px 6px 6px 6px;
            padding: 7px 10px;
            font-weight: 400;
            font-size: 14px;
            color: #7c8797;
            .title_box {
              display: flex;
              font-weight: 400;
              font-size: 14px;
              color: #243752;
              .text {
                max-width: 70%;
              }
            }
            .btn {
              width: 48px;
              height: 20px;
              background: #e1e3e5;
              border-radius: 4px 4px 4px 4px;
              font-weight: 400;
              font-size: 12px;
              color: #505f75;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 4px;
            }
            .time_box {
              display: flex;
              margin-top: 6px;
              font-weight: 400;
              font-size: 12px;
              color: #a7afba;
              .success_time {
                margin-left: 16px;
              }
            }
          }
          .quest_item_active {
            .btn {
              background: rgba(23, 120, 255, 0.1);
              color: #1778ff;
            }
          }
        }
        .heal_list {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          // gap: 12px;
          .heal_item {
            &::after {
              display: block;
              content: ' ';
              height: 1px;
              background-color: #ebebeb;
              margin: 12px 0px;
            }
            .heal_title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
              font-size: 14px;
              color: #243752;
            }
            .heal_content {
              margin-top: 12px;
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
              height: 38px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-height: 20px;
            }
            &:nth-last-child(1) {
              // background-color: #1dbcc0;
              &::after {
                display: none;
              }
            }
          }
        }
      }
      .d_card1 {
        margin-top: 16px;
      }
      .tab_content1 {
        width: 100%;
        min-height: calc(100vh - 43px);
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #edf1f3;
        padding: 0px 12px;
        .render_card {
          background: #ffffff;
          box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
          border-radius: 12px 12px 12px 12px;
          padding: 16px;
          margin-top: 16px;
          .title {
            font-weight: 500;
            font-size: 16px;
            color: #243752;
          }
          .list1 {
            margin-top: 16px;
            .list1_item {
              height: 42px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .list1_item_name {
                font-weight: 400;
                font-size: 14px;
                color: #505f75;
                max-width: 60vw;
              }
              .list1_item_btn {
                width: 63px;
                height: 26px;
                background: #1778ff;
                border-radius: 40px 40px 40px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 13px;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
        .indicators {
          width: 100%;
          // min-height: 356px;
          background: #ffffff;
          box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
          border-radius: 12px;
          padding: 16px;
          box-sizing: border-box;
          margin-top: 16px;
          .indicators-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            .header-title {
              font-weight: bold;
              font-size: 16px;
              color: #243752;
            }
            .header-rigth {
              font-weight: 400;
              font-size: 12px;
              color: #a7afba;
              display: flex;
              align-items: center;
              .text {
                margin-right: 2px;
              }
              .antd-mobile-icon {
                margin-bottom: 2px;
              }
            }
          }

          .indicators-title {
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .indicators-abnormol {
            .abnormol-content {
              width: 100%;
              border: 1px solid #ffe0d9;
              border-radius: 6px 6px 6px 6px;
              padding: 0px 12px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              background: #fff8f6;
              .abnormol-item {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: bold;
                .item-title {
                  width: 84px;
                  white-space: nowrap;
                  font-size: 14px;
                  color: #243752;
                }
                .item-num {
                  font-size: 14px;
                  color: #1dbcc0;
                }
                .abnormol-state {
                  font-size: 14px;
                  color: #eb5e3d;
                }
              }
              .abnormol-item + .abnormol-item {
                border-top: 1px solid #e6ebe9;
              }
            }
          }
          .normol {
            border: 1px solid #cce7e7 !important;
            background: #f5faf8 !important;
            .normol-text {
              color: #00C242 !important;
            }
          }
        }
      }
      .tab_content2 {
        height: calc(100vh - 43px);
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        width: 100%;
        background-color: #edf1f3;
        padding: 0px 12px;
      }
    }
  }
  .item-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader-cell {
    width: 32px;
    height: 32px;
  }
  .adm-space-item {
    width: 32px;
    height: 32px;
  }
  .adm-image-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .mine-col {
    margin-bottom: 16px;
  }
}

@root-entry-name: default;