@import '../../../style/vars.less';

.guide_container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f0f3f5;
  padding-bottom: 84px;
  .content {
    padding: 0px 12px;
    .card {
      margin-top: 12px;
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding: 16px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 12px;
        .type {
          font-weight: 500;
          font-size: 16px;
          color: #243752;
        }
        .status {
          font-weight: 400;
          font-size: 13px;
        }
        .init {
          color: #e32525;
        }
        .read {
          color: #a7afba;
        }
      }
      .card-content {
        .card-item {
          margin-top: 12px;
          display: flex;
          overflow: hidden;
          .card-label {
            font-weight: 400;
            font-size: 13px;
            color: #7c8797;
            min-width: 52px;
            text-wrap: nowrap;
          }
          .card-text {
            margin-left: 24px;
            font-weight: 500;
            font-size: 13px;
            color: #243752;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@root-entry-name: default;