@import '~@/style/vars.less';

.@{layout} {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .FloatingBubble {
    --initial-position-bottom: 100px;
    --initial-position-right: 0px;
    --edge-distance: 24px;
    z-index: 9999;
    --edge-distance: 0px;
    --background: transparent;
    .FloatingBubble-img {
      width: 44px;
      height: 44px;
    }
    .Close-img {
      width: 18px;
      height: 18px;
    }
    .Bubble-content {
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
    .adm-floating-bubble-button {
      width: 48px;
      height: 100px;
      padding: 10px;
    }
  }

  .@{layout}-main {
    flex: 1;
    overflow: auto;
  }

  .@{layout}-tabbar {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    // ios安全区
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
  }
}
.login_loading {
  margin: 200px auto;
  color: #fff;
}
.change_host {
  z-index: 9999;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
}
.change_host1 {
  position: fixed;
  bottom: 80px;
  right: 30px;
  border-radius: 50%;
  opacity: 0.5;
  background-color: black;
  color: #fff;
  width: 50px;
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
  text-align: center;
  border: solid 1px var(--adm-color-border);
}
.render-empty {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  .render-img {
    width: 160px;
    height: 160px;
  }
  .render-empty-text {
    font-weight: 400;
    font-size: 14px;
    color: #7c8797;
    margin-top: 16px;
  }
}

@root-entry-name: default;