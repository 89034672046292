@import '../../style/vars.less';
@primary-color: #1778ff;

.health_care_order_detail {
  padding: 16px 12px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 0px 16px;
    line-height: 1;
  }
  .card1 {
    .header {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #243752;
      border-bottom: 1px solid #f5f5f5;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 500;
        font-size: 18px;
        color: #243752;
      }
      .state {
        font-weight: 500;
        font-size: 14px;
        color: #243752;
      }
      .state1 {
        color: @primary-color;
      }
      .state2 {
        color: #a7afba;
      }
    }
    .content {
      padding: 16px 0px;
      display: flex;
      align-items: center;
      .avater {
        width: 42px;
        height: 42px;
        border-radius: 50%;
      }
      .infos {
        margin-left: 16px;
        .name_box {
          line-height: 1;
          font-weight: 500;
          font-size: 15px;
          color: #243752;
          line-height: 15px;
          display: flex;
          align-items: center;
          .text {
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
            margin-left: 6px;
          }
        }
        .unit {
          margin-top: 9px;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .text2 {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          margin-top: 4px;
        }
      }
      .price {
        margin-left: auto;
        font-weight: 500;
        font-size: 14px;
        color: #243752;
      }
    }

    .price_box {
      display: flex;
      height: 54px;
      border-top: 1px solid #f5f5f5;
      align-items: center;
      .label {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
      }
      .price {
        margin-left: 24px;
        font-weight: 600;
        font-size: 12px;
        color: #ff6023;
        .unit {
        }
        .price_integer {
          font-size: 18px;
        }
        .price_fractional {
        }
      }
    }
  }
  .card2 {
    padding: 20px 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    // gap: 12px;
    .mixin_gap_column(12px);

    .infos {
      display: flex;
      .label {
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
      }
      .value {
        font-weight: 400;
        font-size: 13px;
        color: #243752;
        margin-left: 24px;
      }
    }
  }
}

@root-entry-name: default;