.InvoiceSuccess-container {
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  box-sizing: border-box;
  .success {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 36px;
    box-sizing: border-box;
    .icon {
      width: 48px;
      height: 48px;
      // border: 1px solid red;
      border-radius: 50%;
      margin-bottom: 16px;
    }
    .prompt {
      font-weight: bold;
      font-size: 18px;
      color: #243752;
      margin-bottom: 16px;
    }
    .text {
      font-weight: 400;
      font-size: 12px;
      color: #7c8797;
      text-align: center;
    }
  }
  .content {
    width: 100%;

    padding: 0px 36px;
    box-sizing: border-box;
    margin-bottom: 80px;
    .invoice-content {
      width: 100%;
      min-height: 190px;
      background: #f5f6f7;
      border-radius: 12px;
      margin-top: 50px;
      padding: 10px 16px;
      box-sizing: border-box;
      .invoice-item {
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
        display: flex;
        .item-title {
          width: 80px;
          font-weight: 400;
          font-size: 13px;
          color: #7c8797;
          margin-right: 16px;
        }
        .item-text {
          font-weight: 400;
          font-size: 13px;
          color: #243752;
        }
      }
    }
  }
  .footer {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // position: fixed;
    // bottom: 100px;
    // left: 0px;
    .btn {
      font-weight: 400;
      font-size: 13px;
      color: #1778ff;
      line-height: 14px;
      text-decoration-line: underline;
      margin-top: 16px;
    }
  }
  .footerBtn {
    width: 201px;
    height: 42px;
    background: #eb5e3d;
    border-radius: 59px 59px 59px 59px;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
  }
}

@root-entry-name: default;