.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.noscroll {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}
.adm-nav-bar {
  position: relative;
  z-index: 99;
  display: none !important;
}
.header-bg {
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  .img-bg {
    width: 100%;
    height: 100%;
  }
  // background: linear-gradient( 180deg, #000000 0%, rgba(0,0,0,0) 100%);
}
.tabBarIcon {
  width: 1.375rem;
  height: 1.375rem;
}
.adm-tab-bar-item-active {
  color: #1778ff !important;
}

@root-entry-name: default;