@import '../../style/vars.less';
.changePlanPopup {
  .user_right {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-left: auto;
    display: flex;
    align-items: center;

    .change_btn {
      width: 74px;
      height: 20px;
      background: rgba(23,120,255,0.1);
      border-radius: 27px 27px 27px 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      font-weight: 400;
      font-size: 12px;
      color: #1778FF;
      img {
        width: 10px;
        margin-right: 2px;
      }
    }
  }
}
.changePlanPopup_popup {
  .adm-popup-body {
    min-height: 50vh;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
  }
  .colos {
    color: #d9d9d9;
    right: 20px;
    top: 17px;
    font-size: 22px;
    position: absolute;
  }
  .popup_content {
    .popup_header {
      height: 56px;
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      color: #243752;
    }
    .popup_list {
      background-color: #ffffff;
      padding: 12px;
      display: flex;
      flex-direction: column;
      .mixin_gap_column(12px);
      overflow: auto;
      min-height: 50vh;
      max-height: 65vh;
      .plan {
        background: #f3f5f7;
        border-radius: 6px 6px 6px 6px;
        padding: 12px 13px;
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .avtive_icon {
          color: rgb(23, 120, 255);
          font-size: 16px;
        }
        .plan_text {
          max-width: 70vw;
        }
      }
      .active_plan {
        background: rgba(23, 120, 255, 0.15);
      }
    }
  }
}
@root-entry-name: default;