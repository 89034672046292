@import '../../../style/vars.less';

.health_care_anomaly-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f0f3f5;
  max-height: 100vh;
  overflow: auto;
  .top {
    height: 130px;
    min-height: 130px;
    // background: #ffffff;
    .fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 99;
    }
  }
  .health_care_anomaly-step-container {
    background-color: #f0f3f5;
    flex: 1;
    overflow: auto;
    padding-bottom: 80px;
    .adm-steps-vertical {
      padding: 0px 16px 8px 2px;
    }
    .adm-step-icon-dot {
      color: #7c8797;
      width: 10px;
      height: 10px;
    }
    .adm-step .adm-step-indicator::after {
      width: 2px;
      background: repeating-linear-gradient(
        to bottom,
        #d3d7dc 0 6px,
        transparent 6px 12px
      );
    }
    .adm-step-title {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      .health_care_anomaly_title {
        display: flex;
        .anchor {
          visibility: hidden;
        }
        .health_care_anomaly_history {
          margin-left: auto;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          cursor: pointer;
        }
      }
    }
  }
}

@root-entry-name: default;