@import '../../../style/vars.less';

.patient-report-container {
  background: #f0f3f5;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  .top {
    height: 150px;
    background: #ffffff;
    .fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 99;
    }
    .search {
      width: calc(100% - 24px);
      height: 100%;
      background: #f5f5f5;
      border-radius: 16px;
      --border-radius: 16px;
      --adm-color-primary: #4f8ef5;
      margin: 6px auto;
    }
    .report-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 9px 0px 0px 4px;
      box-sizing: border-box;
      align-items: center;
      .left {
        display: flex;
        flex: 1;
        overflow-x: auto;
        .report-item {
          width: fit-content;
          flex-shrink: 0;
          padding: 6px 5px;
          font-weight: 400;
          font-size: 13px;
          color: #7c8797;
        }
        .activeReport {
          width: fit-content;
          font-weight: 500;
          font-size: 13px;
          color: #1778ff;
        }
        .report-item + .report-item {
          margin-left: 8px;
        }
      }
      .rigth {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        padding-right: 12px;
        padding-left: 8px;
        border-left: 1px solid #ebebeb;
        height: 15px;
        margin-left: 8px;
        .icon {
          margin-left: 2px;
        }
        .rigth-left {
          width: 20px;
          height: 100%;
          opacity: 0.1;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .patient-report-step-container {
    .report-content {
      width: 100%;
      padding-left: 5px;
      padding-right: 12px;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding-bottom: 80px;
      .report-step {
        .adm-steps {
          padding: 20px 0px;
          .adm-step-icon-dot {
            color: #7c8797;
            width: 10px;
            height: 10px;
          }
          .adm-step-title {
            font-weight: 500;
            font-size: 14px;
            color: #243752;
          }
          .adm-step-indicator::after {
            width: 2px;
            background: repeating-linear-gradient(
              to bottom,
              #d3d7dc 0 6px,
              transparent 6px 12px
            );
          }
          .adm-step-icon-container {
            box-sizing: content-box;
          }
          .adm-step-title {
            font-weight: 400;
            font-size: 14px;
            color: #505f75;
          }
          .adm-step-description {
            padding-top: 12px;
          }
        }
      }
    }
  }
  .report-StepItem {
    width: 100%;
    min-height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 6px 6px 6px 6px;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    max-width: calc(100vw - 41px);

    .left-absolute {
      width: 44px;
      height: 16px;
      background: #269df3;
      border-radius: 0px 12px 0px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .report-StepItem-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: fit-content;
      margin-bottom: 16px;
      .rigth {
        font-weight: 400;
        font-size: 14px;
        color: #a7afba;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        color: red;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        color: #243752;
        width: 80%;
      }
    }
    .report-StepItem-content {
      width: 100%;
      .row {
        display: flex;
        justify-content: space-between;
        .time,
        .text {
          font-weight: 400;
          font-size: 12px;
          color: #a7afba;
        }
      }
    }
    .imgContent {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .mixin_gap_row(10px);
      .imgContent-item {
        width: 32px;
        height: 32px;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #d6d6d6;
      }
    }
  }
  .render-mine-empty {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    color: #7c8797;
    .render-img {
      width: 124px;
      height: 124px;
      margin-bottom: 16px;
    }
  }
}

@root-entry-name: default;