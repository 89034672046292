@import '../../style/vars.less';

.service_order_pay {
  padding: 16px 12px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .price_box {
    margin: 53px auto 0px;
    font-weight: 600;
    font-size: 24px;
    color: #243752;
    text-align: center;

    .price {
      font-size: 40px;
    }
  }
  .georges {
    margin: 18px auto 0px;
    font-weight: 400;
    font-size: 12px;
    color: #7c8797;
    text-align: center;
  }
  .pay_manner {
    font-weight: 400;
    font-size: 12px;
    color: #7c8797;
    margin-top: 53px;
    margin-left: 24px;
  }
  .pay_manner_list {
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    // gap: 16px;
    .mixin_gap_column(16px);
    align-items: center;
    margin-top: 21px;
    .pay_manner_list_item {
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      display: flex;
      align-items: center;
      padding: 0px 12px;

      .icon {
        width: 24px;
        height: 24px;
      }
      .name {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
        margin-left: 12px;
      }
      .radio {
        padding: 4px;
        margin-left: auto;
      }
    }
  }
  .pay_btn {
    width: 335px;
    height: 42px;
    background: @primary-color;
    border-radius: 59px 59px 59px 59px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    position: fixed;
    bottom: 40px;
    margin: auto;
    left: 0;
    right: 0;
  }
}

@root-entry-name: default;