@import '../../../style/vars.less';

.indicator_details-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f0f3f5;
  padding-bottom: 80px;
  max-height: 100vh;
  overflow: auto;
  .top {
    height: 122px;
    min-height: 122px;
    background: #ffffff;
    .fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: #ffffff;
      z-index: 99;
    }
  }
  .indicator_details-step-container {
    background-color: #f0f3f5;
    padding: 16px 12px;
    .i_title {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
    }
    .rule {
      margin-top: 12px;
    }
    .adm-steps-vertical {
      padding: 8px 16px 8px 2px;
    }
    .adm-step-icon-dot {
      color: #7c8797;
      width: 10px;
      height: 10px;
    }
    .adm-step .adm-step-indicator::after {
      width: 2px;
      background: repeating-linear-gradient(
        to bottom,
        #d3d7dc 0 6px,
        transparent 6px 12px
      );
    }
    .adm-step-title {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
    }
  }
}

@root-entry-name: default;