@import '../../style/vars.less';
@primary-color: #1778ff;

.health_care_order_list {
  width: 100%;
  flex: 1;
  // height: calc(100vh - 0px);
  height: 100%; //tabbar
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f0f3f5;
  .tabs {
    .adm-tabs-header {
      border-bottom: none;
      background: #ffffff;
    }
    .adm-tabs-tab-line {
      height: 3px;
      background-color: @primary-color;
    }
    .adm-tabs-tab {
      font-weight: 400;
      font-size: 16px;
      color: #505f75;
    }
    .adm-tabs-tab-active {
      font-weight: 500;
      font-size: 16px;
      color: @primary-color;
    }
    .adm-tabs-content {
      padding: 0px;
    }
  }
}

@root-entry-name: default;