.reportQuery {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f7fa;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &_title {
    width: 100vw;
    // height: 15.5vh;
    z-index: 1;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    &_user {
      margin: 5px 10px;
      display: flex;
      align-items: center;
      img {
        width: 50px;
      }
      h3 {
        font-weight: 500;
        font-size: 16px;
        margin: 0 12px;
      }
      .i {
        margin: 0 2px;
      }
      p {
        color: #ccc;
      }
    }
    &_btn {
      .active {
        color: #3c8be5;
        background-color: #e7f2ff;
      }
      Button {
        padding: 5px 25px !important;
        margin: 5px;
        background-color: #f1f3f5;
      }
    }
    .divider {
      margin: 10px 0;
    }
  }
  &_content {
    list-style: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    li {
      margin: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      .reportQuery_content_left {
        h3 {
          font-weight: bold;
        }
        p {
          margin: 5px 0;
        }
      }
      .reportQuery_content_right {
      }
    }
  }
}

@root-entry-name: default;