@import '../../style/vars.less';

.mine-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background: #ecf8f8;
  display: flex;
  flex-direction: column;
  .mine-col {
    margin-bottom: 16px;
  }
  .mine-header {
    display: flex;
    width: 100%;
    min-height: 46px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 0px 0px 24px 24px;
    padding: 16px 36px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 14px;
    color: #243752;
    overflow-x: auto;
    .header-item {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .headerItemActive {
      position: relative;
      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 4px;
        background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
        border-radius: 20px 20px 20px 20px;
      }
    }
    .header-item + .header-item {
      margin-left: 36px;
    }
  }
  .mine-content {
    width: 100%;
    flex: 1;
    padding: 0px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .mine-info {
      width: 100%;
      //   height: 162px;
      background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      .info-name {
        font-weight: bold;
        font-size: 18px;
        color: #ffffff;
        margin-bottom: 8px;
      }
      .info-contnet {
        width: 100%;
        display: flex;
        .info-col {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          position: relative;
          padding-right: 10px;
          display: flex;
          align-items: center;
        }

        .info-col + .info-col {
          padding-left: 10px;
          padding-right: 10px;
          &::after {
            display: block;
            content: '';
            width: 1px;
            height: 8px;
            background: #ebebeb;
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
          }
        }
      }
    }
    .indicators {
      width: 100%;
      // min-height: 356px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .indicators-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        .header-title {
          font-weight: bold;
          font-size: 16px;
          color: #243752;
        }
        .header-rigth {
          font-weight: 400;
          font-size: 12px;
          color: #a7afba;
          display: flex;
          align-items: center;
          .text {
            margin-right: 2px;
          }
          .antd-mobile-icon {
            margin-bottom: 2px;
          }
        }
      }

      .indicators-title {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .indicators-abnormol {
        .abnormol-content {
          width: 100%;
          border: 1px solid #ffe0d9;
          border-radius: 6px 6px 6px 6px;
          padding: 0px 12px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          background: #fff8f6;
          .abnormol-item {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            .item-title {
              width: 84px;
              white-space: nowrap;
              font-size: 14px;
              color: #243752;
            }
            .item-num {
              font-size: 14px;
              color: #1dbcc0;
            }
            .abnormol-state {
              font-size: 14px;
              color: #eb5e3d;
            }
          }
          .abnormol-item + .abnormol-item {
            border-top: 1px solid #e6ebe9;
          }
        }
      }
      .indicators-adnormol-myHealth {
        // background-color: aqua;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          width: 25%;
          text-align: center;
        }
      }
      .normol {
        border: 1px solid #cce7e7 !important;
        background: #f5faf8 !important;
        .normol-text {
          color: #00c242 !important;
        }
      }
    }
    .mine-indicator {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      .indicator-header {
        width: 100%;
        display: flex;
        margin-bottom: 16px;
        .header-item {
          height: 56px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 6px 12px;
          box-sizing: border-box;
          .icon {
            width: 24px;
            height: 24px;
          }
        }
        .header-item + .header-item {
          margin-left: 10px;
        }
        .activeItem {
          background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%), #d9d9d9;
          color: #ffffff;
          position: relative;
          &::after {
            position: absolute;
            bottom: -8px;
            display: block;
            content: '';
            width: 25px;
            height: 10px;
            // background: #25c5c8;
            border-radius: 0px 0px 0px 0px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 5px solid #25c5c8; /* 改变颜色改变箭头颜色 */
          }
        }
      }
      .err-content {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        margin-bottom: 16px;
        .err-header {
          display: flex;
          align-items: center;
          // margin-bottom: 14px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .text {
            font-weight: 500;
            font-size: 16px;
            color: #eb5e3d;
          }
        }
      }
      .success-content {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        .success-header {
          display: flex;
          align-items: center;
          // margin-bottom: 14px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          .text {
            font-weight: 500;
            font-size: 16px;
            color: #00c242;
          }
        }
      }
      .report-content {
        width: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        .report-header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 9px 0px;
          padding-right: 0px;
          box-sizing: border-box;
          .left {
            display: flex;
            flex: 1;
            overflow-x: auto;

            .report-item {
              width: fit-content;
              flex-shrink: 0;
              padding: 6px 10px;
              background: #f7f7f7;
              border-radius: 6px 6px 6px 6px;
              font-weight: 400;
              font-size: 13px;
              color: #7c8797;
            }
            .activeReport {
              width: fit-content;
              border: 1px solid #1dbcc0;
              background: rgba(29, 188, 192, 0.1);
              font-weight: 500;
              font-size: 13px;
              color: #1dbcc0;
            }
            .report-item + .report-item {
              margin-left: 8px;
            }
          }
          .rigth {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            font-weight: 400;
            font-size: 14px;
            color: #243752;
            .icon {
              margin-left: 2px;
            }
            .rigth-left {
              width: 20px;
              height: 100%;
              opacity: 0.1;
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
        .report-step {
          .adm-steps {
            padding: 20px 0px;
            .adm-step-icon-dot {
              width: 7px;
              height: 7px;
              background: #1dbcc0;
              border-radius: 0px 0px 0px 0px;
              border: 4px solid #8ededf;
              border-radius: 50%;
            }
            .adm-step-indicator::after {
              width: 2px;
              background: repeating-linear-gradient(
                to bottom,
                #d3d7dc 0 6px,
                transparent 6px 12px
              );
            }
            .adm-step-icon-container {
              box-sizing: content-box;
            }
            .adm-step-title {
              font-weight: 500;
              font-size: 14px;
              color: #243752;
            }
            .adm-step-description {
              padding-top: 12px;
            }
          }
        }
      }
      // .IndicatorItem1 + .IndicatorItem1 {
      //   margin-top: 14px;
      // }
    }
    .render-mine-empty {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 500;
      font-size: 14px;
      color: #7c8797;
      .render-img {
        width: 124px;
        height: 124px;
        margin-bottom: 16px;
      }
    }
  }
  .item-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader-cell {
    width: 32px;
    height: 32px;
  }
  .adm-space-item {
    width: 32px;
    height: 32px;
  }
  .adm-image-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
}
.step3 {
  margin: 175px auto 0;
  width: calc(100vw - 24px);
  position: relative;
  .step3_img {
    width: 100%;
    z-index: 999;
  }
  .step3_btn {
    position: absolute;
    margin-top: 148px;
    margin-left: auto;
    margin-right: 12px;
    width: 112px;
    height: 36px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }
  .box {
    width: 12px;
    height: 12px;
    background: #1dbcc0;
    border-radius: 50%;
    position: absolute;
    left: 74px;
    bottom: 0px;
    .box_con {
      position: absolute;
      width: 20px;
      height: 20px;
      background: #1dbcc0;
      border-radius: 50%;
      opacity: 0.2;
      left: -4px;
      top: -4px;
    }
    .box_line {
      width: 2px;
      height: 60px;
      background: #1dbcc0;
      position: absolute;
      bottom: -50px;
      left: 5px;
      .step3_text {
        position: absolute;
        bottom: -36px;
        left: -37px;
        height: 36px;
      }
    }
  }
}
.step3_tabBar_item {
  position: absolute;
  bottom: 0;
  right: calc((20% - 60px) / 2);
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 9px 9px 9px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // ios安全区
  bottom: env(safe-area-inset-bottom);
  bottom: constant(safe-area-inset-bottom);
  img {
    width: 19px;
    height: 19px;
  }
  .tabBar_item_text {
    font-weight: 400;
    font-size: 10px;
    color: #1778ff;
    margin-top: 6px;
  }
}

.IndicatorItem1 {
  width: 100%;
  padding: 14px 0px;
  // padding-top: 0px;
  border-bottom: 1px solid #ebebeb;
  .IndicatorItem-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .left {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      width: 50%;
    }
    .rigth {
      .num {
        font-weight: 500;
        font-size: 14px;
        color: #505f75;
        margin-right: 24px;
      }
      .state {
        font-weight: 500;
        font-size: 14px;
        color: #00c242;
        min-width: 28px;
        display: inline-block;
      }
      .errState {
        color: #eb5e3d;
      }
    }
  }
  .IndicatorItem-bottom {
    width: 100%;
    .item {
      font-weight: 400;
      font-size: 12px;
      color: #7c8797;
    }
    .item + .item {
      margin-top: 6px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
.StepItem {
  width: 100%;
  min-height: 70px;
  background: #f7f7f7;
  border-radius: 12px 12px 12px 12px;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  max-width: calc(100vw - 80px);
  .left-absolute {
    width: 44px;
    height: 16px;
    background: #269df3;
    border-radius: 0px 12px 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .StepItem-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: fit-content;
    line-height: 1;
    .rigth {
      font-weight: 400;
      font-size: 14px;
      color: #a7afba;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      color: red;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      width: 80%;
    }
  }
  .StepItem-content {
    width: 100%;
    margin-top: 16px;
    .row {
      display: flex;
      justify-content: space-between;
      .time,
      .text {
        font-weight: 400;
        font-size: 12px;
        color: #a7afba;
      }
    }
  }
  .imgContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .mixin_gap_row(10px);
    .imgContent-item {
      width: 32px;
      height: 32px;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #d6d6d6;
    }
  }
}

@root-entry-name: default;