@import '../../style/vars.less';

.report_table {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
  border-radius: 12px 12px 12px 12px;
  padding: 8px;
  table {
    border-collapse: collapse; /* 合并边框 */
    width: 100%;
  }
  thead {
    border-bottom: 1px solid #f5f5f5;
  }
  td,
  th {
    border: none; /* 无边框 */
    padding: 8px; /* 内边距 */
    text-align: left;
  }
  th {
    font-weight: 400;
    font-size: 12px;
    color: #7c8797;
  }
  td {
    font-weight: 400;
    font-size: 14px;
    color: #243752;
  }
  .tallImg {
    width: 14px;
    height: 14px;
  }
  .item1 {
    max-width: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #243752;
  }
  .time2 {
    span {
      display: flex;
      align-items: center;
    }
  }
  .abnormalV {
    color: #eb5e3d;
  }
  .empty {
    width: 42px;
    height: 42px;
  }
  .text {
    margin-bottom: 24px;
  }
}

@root-entry-name: default;