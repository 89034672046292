@import '../../style/vars.less';
.task_detail {
  padding: 16px 12px;
  background: #fff;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  // padding-bottom: 60px;
  > div {
    width: 100%;
    height: 100%;
  }
  .bf-controlbar {
    display: none;
  }
  .public-DraftStyleDefault-ltr {
    height: fit-content;
  }
  .public-DraftEditor-content {
    padding: 0px !important;
  }
  .bf-content {
    height: 100%;
    .button-remove {
      display: none;
    }
  }
}
.taskBtnBack {
  background-color: white;
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 10;
  .taskDetailBtn {
    position: fixed;
    // bottom: 20px;
    // left: 20%;
    width: 60%;
    height: 50px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: white;
    letter-spacing: 8px;
    width: 277px;
    height: 38px;
    background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
    border-radius: 59px;
    z-index: 10;
  }
}
.warnBox {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .closeIcon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .warnIcon {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .warnTitle {
    color: #243752;
    font-size: 18px;
    font-weight: 500;
    margin-top: 12px;
  }
  .warnText {
    color: #505f75;
    font-size: 14px;
    margin-top: 12px;
  }
  .warnBtn {
    margin-top: 12px;
    text-align: center;
    font-size: 16px;
    line-height: 42px;
    color: white;
    width: 231px;
    height: 42px;
    background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
    border-radius: 59px 59px 59px 59px;
  }
}

@root-entry-name: default;