.IndicatorDetails-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
  background: #f0f3f5;
  padding: 60px 12px 30px 12px;
  .IndicatorDetails-header {
    width: 100%;
    height: 44px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(180, 178, 200, 0.25);
    display: flex;
    font-weight: 400;
    font-size: 14px;
    color: #7c8797;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 22px;
    padding-right: 0px;
    position: fixed;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    z-index: 10;
    .left {
      flex: 1;
      height: 100%;
      overflow-x: auto;
      display: flex;
      padding-right: 8px;
    }
    .header-item {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    .activeItem {
      color: #1dbcc0;
    }
    .sign {
      position: relative;
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 2px;
        right: -6px;
        width: 6px;
        height: 6px;
        background: #e32525;
        border-radius: 50%;
      }
    }
    .header-item + .header-item {
      margin-left: 17px;
    }
    .rigth {
      // height: 100%;
      padding-right: 22px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      padding-left: 8px;
      border-left: 1px solid #ebebeb;
      background-color: #ffffff;
      .icon {
        margin-left: 2px;
      }
      .rigth-left {
        width: 20px;
        height: 100%;
        opacity: 0.1;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .IndicatorDetails-content {
    .indicator-header {
      padding-bottom: 12px;
      border-bottom: 1px solid #f5f5f5;
      font-weight: bold;
      font-size: 16px;
      color: #243752;
    }
    .indicator-opinion {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      margin-bottom: 16px;
      .adm-page-indicator-dot {
        width: 5px;
        height: 5px;
        background: #d3d7dc;
        border-radius: 50%;
      }
      .adm-page-indicator-dot-active {
        background: #243752;
      }
    }
    .record {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px;
      padding: 16px;
      box-sizing: border-box;
      .record-header {
        padding-bottom: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #243752;
        display: flex;
        justify-content: space-between;
        .rigth {
          width: 112px;
          height: 30px;
          background: #0abbb9;
          display: flex;
          align-items: center;
          border-radius: 4px;
          // border: 2px solid #0abbb9;
          box-sizing: border-box;
          overflow: hidden;
          padding: 1px;
          .btn {
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .activeBtn {
            color: #0abbb9;
            background-color: #ffffff;
            border-radius: 4px 0px 0px 4px;
          }
          .activeBtn1 {
            color: #0abbb9;
            background-color: #ffffff;
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
      .item {
        width: 100%;
        padding: 14px 6px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #243752;
        .item-info {
          width: 100%;
          display: flex;
          align-items: center;
          .text {
            font-weight: 500;
            font-size: 14px;
            color: #eb5e3d;
            margin-top: 0px;
          }
        }
        .text {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          margin-top: 12px;
        }
        .time {
          flex: 2;
          flex-shrink: 0;
          height: 32px;
          display: flex;
          align-items: center;
        }
        .time1 {
          width: 85px;
          flex-shrink: 0;
          text-wrap: nowrap;
        }
        .time2 {
          flex: 1;
          padding-left: 4px;
        }
        .time3 {
          flex: 2;
          padding-left: 4px;
        }
        .time4 {
          width: 19%;
          flex-shrink: 0;
          padding-left: 4px;
        }
        .num {
          flex: 1;
          flex-shrink: 0;
        }
      }
      .item + .item {
        border-top: 1px solid #f5f5f5;
      }
      .item-header {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
        padding: 10px 6px;
        flex-direction: row;
        align-items: center;
      }
      .chart {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        .indicatorChart {
          // flex: 1;
          width: 100%;
          height: 238px;
          // border: 1px red solid;
        }
        .tag {
          display: flex;
          align-items: center;
          margin-left: 35px;
          img {
            width: 12px;
            height: 12px;
          }
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .title {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .charts {
          position: absolute;
          width: 100%;
          height: 238px;
          top: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff;
          img {
            width: 50%;
            margin-top: 20px;
          }
          span {
            padding: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .table-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
  .adm-image-uploader {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
    position: relative;
    z-index: 1;
  }
  .adm-image-uploader-cell {
    width: 32px;
    height: 32px;
  }
  .adm-space-item {
    width: 32px;
    height: 32px;
  }
  .adm-image-img {
    width: 32px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #d6d6d6;
  }
}
.IndicatorItem {
  width: 100%;
  height: 160px;
  overflow-y: auto;
  margin-top: 16px;
  .item-title {
    font-weight: 400;
    font-size: 13px;
    color: #7c8797;
  }
  .item-content {
    display: flex;
    width: 100%;
    margin-top: 12px;
    .title {
      font-weight: 400;
      font-size: 14px;
      color: #7c8797;
      width: 80px;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .text {
      flex: 1;
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      word-wrap: break-word;
      word-break: break-all;
    }
    .url {
      color: #1778ff;
    }
  }
}

@root-entry-name: default;