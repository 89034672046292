@import '../../style/vars.less';
.specialist_serrvice_detail {
  padding: 16px 12px 100px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  .head_pay {
    height: 50px;
    background: #fffaf2;
    border-radius: 6px 6px 6px 6px;
    padding: 6px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    .box {
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #e6e6e6;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      .lable {
        font-weight: 500;
        font-size: 14px;
        color: @primary-color;
      }
      .time {
        font-weight: 400;
        font-size: 14px;
        color: @primary-color;
        margin-left: 12px;
      }
    }
  }
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    padding: 16px 16px 11px;
    line-height: 1;
    width: 100%;
    border-radius: 12px 12px 12px 12px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin-top: 16px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .state {
        font-weight: 400;
        font-size: 14px;
      }
      .state1 {
        color: @primary-color;
      }
      .state2 {
        color: #a7afba;
      }
    }
    .line {
      height: 1px;
      background-color: #f5f5f5;
      margin: 12px 0px;
    }
    .infos {
      margin-top: 12px;
      display: flex;
      .label {
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
        min-width: 65px;
      }
      .value {
        font-weight: 400;
        font-size: 13px;
        color: #243752;
        margin-left: 24px;
      }
      .value1 {
        color: @primary-color;
      }
    }

    .price_box {
      display: flex;
      margin-top: -5px;
      align-items: center;
      // height: 28px;
      margin-top: 12px;
      .label {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
      }
      .price {
        margin-left: 24px;
        font-weight: 600;
        font-size: 12px;
        color: #ff6023;
        .unit {
        }
        .price_integer {
          font-size: 18px;
        }
        .price_fractional {
        }
      }
      .btn {
        // margin-left: auto;
        width: 92px;
        height: 28px;
        border-radius: 28px 28px 28px 28px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
          display: none;
        }
      }
      .btn + .btn {
        margin-left: 20px;
      }
      .pay_btn {
        background: @primary-color;
        color: #ffffff;
      }

      .pay_btn1 {
        width: 78px;
        background: #ffffff;
        border: 1px solid @primary-color;
        color: @primary-color;
      }
      .order_detail_btn {
        background: #ffffff;
        border: 1px solid #d6d6d6;
        color: #243752;
      }
    }
    .btn-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .line + .price_box {
      margin-top: 0px;
    }
  }
  .online_consultation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0px 20px;
    margin-top: 32px;
    .title {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
    }
    .tit_exita {
      color: #1778ff;
    }
  }
  .card_list {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    // width: calc(100% - 40px);
    margin: 24px auto 0px;
    .list_item {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0px 16px;
      .icon {
        width: 20px;
        height: 20px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        color: #243752;
        margin-left: 8px;
      }
      .right {
        margin-left: auto;
      }
    }
  }
  .bg_card {
    background: linear-gradient(180deg, @primary-color 0%, #ffede9 100%);
    border-radius: 12px 12px 12px 12px;
    padding: 8px;
    line-height: 1;
    margin-top: 16px;
    .header {
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      margin: 16px 0px;
      text-align: center;
    }
    .bg_card_card {
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      padding: 12px;
      .card_item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 6px;
        .mixin_gap_column(6px);
        padding: 12px;
        box-sizing: border-box;
        border: none;
        border-width: 0px;
        .icon {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
        }
        .name {
          font-family:
            PingFang SC,
            PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          line-height: 16px;
          height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .total_num {
          font-weight: 400;
          font-size: 10px;
          color: #7c8797;
        }
        .remainder_num {
          font-weight: 400;
          font-size: 10px;
          color: #1778ff;
        }
      }
    }
  }
  .upgrade_line {
    margin-top: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .line {
      flex: 1;
      height: 1px;
      background-color: #d3d7dc;
    }
    .center {
      padding: 0px 10px;
      display: inline-flex;
      align-items: center;
      .img {
        width: 12px;
        height: 12px;
      }
      .text {
        font-weight: 500;
        font-size: 14px;
        color: #a7afba;
        margin-left: 6px;
      }
    }
  }
  .up_service {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 6px 6px 6px 6px;
    }
    .center {
      margin-left: 12px;
      .title {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .info {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .icon {
      padding: 12px 0px 12px 12px;
      margin-left: auto;
      img {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }
  .rate {
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    padding: 13px 12px;
    margin-top: 16px;
    display: flex;
    align-items: center;

    .label {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      width: 60px;
    }
  }
  .user_appraise {
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    padding: 13px 12px;
    margin-top: 16px;

    .label {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #243752;
      width: 60px;
    }
    .text_area {
      --color: #243752;
      --placeholder-color: #d3d7dc;
      --font-size: 13px;
      background: #f7f7f7;
      border-radius: 6px 6px 6px 6px;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 12px;
    }
  }
  .user_appraise_btn {
    // width: 351px;
    height: 42px;
    background: #eb5e3d;
    border-radius: 59px 59px 59px 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-top: 16px;
  }
  .appraise_card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 16px;
    margin-top: 16px;
    .appraise_card_header {
      display: flex;
      .avatar {
        img {
          width: 32px;
          height: 32px;
          border-radius: 21px 21px 21px 21px;
        }
      }
      .user {
        margin-left: 10px;
        .name {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .star {
          margin-top: 5px;
        }
      }
      .date {
        font-weight: 400;
        font-size: 12px;
        color: #a7afba;
        margin-left: auto;
      }
    }
    .appraise_card_content {
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      margin-left: 42px;
      margin-top: 10px;
      .Ellipsis {
        --adm-color-primary: #a7afba;
      }
    }
  }
  .pay_box {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // height: 83px;
    background: #ffffff;
    padding: 6px 20px;
    line-height: 1;
    .left {
      display: flex;
      flex-direction: column;
      // gap: 5px;
      .mixin_gap_column(5px);
      .lable {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .price {
        .num {
          font-weight: 600;
          font-size: 20px;
          color: @primary-color;
        }
        .unit {
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          margin-left: 2px;
        }
      }
      .cancel_btn {
        // margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 149px;
        height: 38px;
        // background: @primary-color;
        border-radius: 59px;
        font-weight: 400;
        font-size: 16px;
        // color: #ffffff;
      }
    }
    .pay_btn {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 149px;
      height: 38px;
      background: @primary-color;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
  .wx_pay_box {
    height: 83px;
  }
}

.specialist_serrvice_detail_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 202px;
  background: linear-gradient(
    180deg,
    @primary-color 70%,
    rgba(235, 94, 61, 0) 100%
  );
}
.nav_bar {
  color: #ffffff;
}
.loading_box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 200px auto;
  width: max-content;
  border-radius: 6px;
  box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
  .loading_text {
    margin-top: 12px;
  }
}
.step1 {
  width: 289px;
  height: 282px;
  border-radius: 16px 16px 16px 16px;
  margin: calc((100vh - 282px) / 2) auto;
  padding: 32px 20px 24px;
  background-image: url('../../assets/step/step1_bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  line-height: 1;
  .title {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #243752;
    text-align: center;
    span {
      color: #1dbcc0;
    }
  }
  .info {
    font-weight: 400;
    font-size: 14px;
    color: #505f75;
    margin-top: 16px;
    line-height: 20px;
    span {
      color: #1778ff;
    }
  }
  .info_img {
    height: 62px;
    margin-top: 24px;
  }
  .step_btn {
    margin-top: 24px;
    width: 249px;
    height: 40px;
    background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%);
    border-radius: 29px 29px 29px 29px;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@root-entry-name: default;