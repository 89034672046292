@import '../../style/vars.less';

.health_care_appraise {
  padding: 20px;
  background: linear-gradient(180deg, #e8f9ff 0%, #f7f7f7 72%);
  width: 100%;
  // height: calc(100vh - 0px);
  height: calc(100vh - 50px); //tabbar
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .card {
    height: 130px;
    background: linear-gradient(90deg, #577af8 0%, #4f8ef5 100%);
    border-radius: 6px 6px 6px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    box-sizing: border-box;
    // gap: 12px;
    .mixin_gap_row(12px);

    position: relative;
    z-index: 1;

    .apprais_avatar {
      width: 56px;
      height: 56px;
      border-radius: 53px 53px 53px 53px;
      border: 2px solid #ffffff;
      // overflow: hidden;
      img {
        width: 56px;
        height: 56px;
        border-radius: 53px 53px 53px 53px;
      }
    }
    .center {
      display: flex;
      flex-direction: column;
      flex: 1;
      // gap: 12px;
      .mixin_gap_column(12px);
      .name {
        font-family:
          PingFang SC,
          PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
      }
      .rate_box {
        font-weight: 500;
        font-size: 18px;
        color: #ffde2f;
        display: flex;
        align-items: center;
        // gap: 7px;
        .mixin_gap_row(7px);
        .rate {
          --star-size: 14px;
          --inactive-color: #ffde2f;
        }
      }
    }
    .right {
      .appraise_num {
        font-weight: 500;
        font-size: 28px;
        color: #ffffff;
      }
      .appraise_text {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .bg {
      position: absolute;
      width: 88%;
      height: calc(100% - 6px);
      top: 12px;
      margin: auto;
      left: 0;
      right: 0;
      background: linear-gradient(90deg, #577af8 0%, #4f8ef5 100%);
      border-radius: 6px 6px 6px 6px;
      opacity: 0.4;
      filter: blur(12px);
      z-index: -1;
    }
  }
  .user_appraise {
    margin-top: 36px;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    margin-top: 16px;
    .appraise_card {
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      padding: 16px;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      &:not(:last-child)::after {
        display: inline-block;
        height: 1px;
        background-color: #f5f5f5;
        content: ' ';
        width: calc(100% - 32px);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      .appraise_card_header {
        display: flex;
        .avatar {
          img {
            width: 32px;
            height: 32px;
            border-radius: 21px 21px 21px 21px;
          }
        }
        .appraise_card_header_right {
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 10px;
          .top {
            display: flex;
            justify-content: flex-end;

            .user {
              .name {
                font-weight: 400;
                font-size: 12px;
                color: #7c8797;
              }
            }
            .detail_btn {
              font-weight: 400;
              font-size: 12px;
              color: #1778ff;
              margin-left: auto;
              display: inline-flex;
              align-items: center;
              height: min-content;
              flex: 1;
              padding-left: 16px;
              justify-content: flex-end;
              // .text {
              //   overflow: hidden;
              //   max-width: 80%;
              // }
            }
          }
        }
        .star {
          margin-top: 5px;
        }
      }
      .appraise_card_content {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        margin-left: 42px;
        margin-top: 10px;
        .Ellipsis {
          --adm-color-primary: #a7afba;
        }
      }
      .date {
        font-weight: 400;
        font-size: 12px;
        color: #a7afba;
        margin-left: 42px;
        margin-top: 10px;
      }
    }
  }
}

@root-entry-name: default;