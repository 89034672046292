.prescription {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &_title {
    width: 100vw;
    // height: 7vh;
    z-index: 1;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    &_user {
      margin: 5px 10px;
      display: flex;
      align-items: center;
      img {
        width: 50px;
      }
      h3 {
        font-weight: 500;
        font-size: 16px;
        margin: 0 12px;
      }
      .i {
        margin: 0 2px;
      }
      p {
        display: flex;
        align-items: center;
        color: #ccc;
      }
    }
    &_btn {
      .active {
        color: #3c8be5;
        background-color: #e7f2ff;
      }
      Button {
        padding: 5px 25px !important;
        margin: 5px;
        background-color: #f1f3f5;
      }
    }
  }
  &_content {
    width: 100%;
    height: 93vh;
    flex-shrink: 1;
    &_ul {
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      &_li {
        list-style: none;
        margin: 10px 15px;
        border-radius: 5px;
        background-color: #fff;
        padding: 15px 10px;
        &_title {
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          &_p {
            display: flex;
            &_rx_img {
              vertical-align: middle;
              width: 25px;
              height: 25px;
              margin-right: 10px;
            }
          }
        }
        &_timer {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
        }
      }
    }
  }
}

@root-entry-name: default;