@import '../../style/vars.less';

.agreement {
  padding: 16px 12px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}

@root-entry-name: default;