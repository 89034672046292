.majordomo-popup-container {
  .majordomo-popup {
    .popup-content {
      width: 279px;
      // min-height: 330px;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      padding: 16px;
      box-sizing: border-box;
      .popup-header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .header-name {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        color: #243752;
      }
      .content {
        width: 100%;
        padding: 0px 8px;
        box-sizing: border-box;
        margin: 16px 0px;
        .title {
          display: flex;
          // align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          flex-shrink: 0;
        }
        .text {
          // display: flex;
          // align-items: center;
          font-weight: 400;
          font-size: 14px;
          color: #243752;
        }
        .phone {
          font-weight: 400;
          font-size: 16px;
          color: #1778ff;
          border-bottom: 1px solid #1778ff;
        }
        .content-item {
          display: flex;
        }
        .content-item + .content-item {
          margin-top: 24px;
        }
        .code {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          margin-top: 32px;
          .code-img {
            width: 100px;
            height: 100px;
            // border: 1px solid red;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

@root-entry-name: default;