@import '../../../style/vars.less';
.PickerPopup {
  width: 100%;
  //   min-height: 198px;
  background: #ffffff;
  border-radius: 0px 0px 16px 16px;
  //   padding: 16px;
  box-sizing: border-box;
  .PickerPopup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #243752;
    padding: 16px;
  }
  .PickerPopup-content {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    flex-wrap: wrap;
    .mixin_gap_row(8px);
    .mixin_gap_column(8px);
    .tag-item {
      width: fit-content;
      height: fit-content;
      font-weight: 400;
      font-size: 13px;
      color: #7c8797;
      background: #f7f7f7;
      border-radius: 6px 6px 6px 6px;
      padding: 10px;
      border: 1px solid #f7f7f7;
    }
    .activeTag {
      background: rgba(29, 188, 192, 0.1);
      border: 1px solid #1dbcc0;
      font-weight: 500;
      font-size: 13px;
      color: #1dbcc0;
      box-sizing: border-box;
    }
  }
  .PickerPopup-bottom {
    width: 100%;
    padding: 12px 16px 16px 16px;
    border-top: 1px solid #ebebeb;
    display: flex;
    .btn {
      flex: 1;
      height: 38px;
      background: #1dbcc0;
      border-radius: 24px 24px 24px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
    .clear {
      font-weight: 400;
      font-size: 14px;
      color: #243752;
      background: #ebebeb;
    }
    .btn + .btn {
      margin-left: 15px;
    }
  }
}

@root-entry-name: default;