@import '../../style/vars.less';
.daily_quest_page {
  width: 100%;
  min-height: calc(100vh - 43px);
  box-sizing: border-box;
  padding: 0px 12px 30px;
  background: #ecf8f8;
  overflow: auto;
  background-image: url('../../assets/bg1.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  .mession {
    width: 100%;
    margin: 8px auto 12px;
    text-align: center;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #7c8797;
    img {
      width: 100%;
    }
  }
  // .bg {
  //   width: 100%;
  //   background-image: url('../../assets/bg1.png');
  // }
  .user_info {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 6px;
    .avatar {
      display: flex;
      align-items: center;
      img {
        width: 42px;
        height: 42px;
        background: #d0eded;
        border-radius: 50%;
        border: 1px solid #aadede;
      }
    }
    .user-info-content {
      flex: 1;
      padding: 0px 8px;
    }
    .user_name_box {
      display: flex;
      align-items: center;
      .mixin_gap_row(8px);
      .user_name {
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
      }
      .user_gender {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
      .user_age {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .user_info-bottom {
      .user_right_text {
        max-width: 50vw;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
      }
    }
    .user_right {
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      margin-left: auto;
      display: flex;
      align-items: center;

      .change_btn {
        width: 50px;
        height: 21px;
        background: rgba(231, 231, 231, 0.5);
        border-radius: 27px 27px 27px 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        img {
          width: 10px;
          margin-right: 2px;
        }
      }
    }
  }
  .err_box {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 0px 14px;
    height: 36px;
    background: #f7e5e1;
    border-radius: 6px 6px 6px 6px;
    img {
      width: 14px;
      height: 14px;
    }
    .err_text {
      font-weight: 400;
      font-size: 14px;
      color: #eb5e3d;
      margin-left: 8px;
    }
  }
  .center-box {
    width: 100%;
    display: flex;
    margin-top: 16px;
    flex-wrap: nowrap;
    .img {
      height: 60px;
    }
    .img1 {
      margin-left: 10px;
      width: calc(50% - 5px);
    }
    .img2 {
      width: 100%;
      height: 60px;
      // height: 52px;
    }
    .adm-badge-wrapper {
      flex: 1;
    }
  }
  .date_box {
    // height: 168px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    padding: 16px;
    margin-top: 16px;
    --adm-color-primary: #bae8e9;
    position: relative;
    // padding-top: 16px;
    .date_change {
      font-weight: 500;
      font-size: 18px;
      color: #243752;
      display: flex;
      align-items: center;
      justify-content: center;
      width: min-content;
      margin: auto;
      .mixin_gap_row(3px);
      .downFill {
        width: 12px;
        height: 12px;
        // margin-bottom: 2px;
        margin-left: 4px;
      }
      .up {
        transform: rotate(180deg);
      }
    }
    .b_calendare {
      .adm-calendar-mark {
        border-bottom: none;
        height: auto;
        .adm-calendar-mark-cell {
          font-weight: 400;
          font-size: 16px;
          color: #7c8797;
        }
      }
      .adm-calendar-header {
        display: none;
      }
      .adm-calendar-cell-selected {
        color: #1dbcc0;
      }
      .adm-calendar-cell {
        position: relative;
        transition: all 0.15s linear;
        justify-content: center;
      }
      .adm-calendar-cell-bottom {
        display: none;
      }
      .execute-custom-cell {
        &::before {
          content: ' ';
          display: block;
          position: absolute;
          width: 6px;
          height: 6px;
          background: #e32525;
          border-radius: 0px 0px 0px 0px;
          right: 4px;
          top: 4px;
          border-radius: 50%;
        }
      }
    }
    .adm-calendar {
      margin-top: 16px;
    }
    .adm-calendar-cells {
      padding: 10px 10px 4px;
    }
    .adm-calendar-cell {
      width: calc(100% / 7);
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 32px;
      // height: 32px;
      // border-radius: 6px 6px 6px 6px;
      // margin: 15px;
      // border: 1px rgb(15, 14, 14) solid;
      > .adm-calendar-cell-top {
        .show-custom-cell {
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        // height: 32px;
        // border: 1px red solid;
        // width: 100%;
        // height: 100%;
      }
    }
    .adm-calendar-cell-selected {
      background: #fff !important;
      .show-custom-cell {
        width: 32px;
        height: 32px;
        background: #bae8e9;
        border-radius: 6px 6px 6px 6px;
      }
    }

    .activeRetract {
      .adm-calendar-cell {
        height: min-content;
        margin-bottom: 0px;
        padding: 0px;
        justify-content: center;
      }
      .adm-calendar-cell-bottom {
        display: none;
      }
      .show-custom-cell {
        height: 48px;
        // margin-bottom: 4px;
        padding: 2px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .open {
      font-weight: 400;
      font-size: 12px;
      color: #243752;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 16px;
      top: 21px;
      .mixin_gap_row(3px);
      .down_icon {
        width: 12px;
        height: 12px;
        margin-bottom: 2px;
      }
      .up {
        transform: rotate(180deg);
      }
    }
  }
  .d_card {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 12px;
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #243752;
    }
    .d_card_list {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .mixin_gap_row(9px);
      flex-wrap: nowrap;
      .d_card_item {
        // min-width: 75px;
        flex: 1;
        max-width: calc(25% - 7px);
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        box-sizing: border-box;
        height: 66px;
        background: #f7f7f7;
        // .mixin_gap_column(4px);
        position: relative;
        padding: 6px;
        .d_name {
          font-weight: 400;
          font-size: 13px;
          color: #243752;
        }
        .d_num {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .card_img {
          height: 19px;
        }
      }
      .active {
        background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%), #ffffff;
        .d_name {
          color: #ffffff;
        }
        .d_num {
          color: #ffffff;
        }
        &::after {
          display: block;
          content: ' ';
          background-image: url(../../assets/Slice56.png);
          width: 25px;
          height: 12px;
          position: absolute;
          bottom: -16px;
          left: 0;
          right: 0;
          margin: auto;
          background-size: 100%;
          z-index: 9;
        }
      }
    }
    .item_info {
      background: #f7f7f7;
      border-radius: 6px 6px 6px 6px;
      // border: 1px solid #ebebeb;
      padding: 12px;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      .title {
        font-weight: 400;
        font-size: 12px;
        color: #7c8797;
      }
      .time {
        font-weight: 400;
        font-size: 12px !important;
        color: #7c8797 !important;
      }
      .seemore {
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mixin_gap_column(12px);
      .quest_item {
        min-height: 42px;
        background: #f3f5f7;
        border-radius: 6px 6px 6px 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
        .text {
          flex: 1;
          // max-width: 70%;
          // display: flex;
          // flex-direction: column;
        }
        .ellipsis1 {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .btn {
          width: 60px;
          height: 24px;
          background: #e1e3e5;
          border-radius: 40px 40px 40px 40px;
          font-weight: 500;
          font-size: 12px;
          color: #505f75;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .quest_item_active {
        background: linear-gradient(90deg, #d7f8f8 0%, #d2f2f2 100%);
        .text {
          font-weight: 400;
          font-size: 14px;
          color: #243752;
        }
        .btn {
          background: #1dbcc0;
          color: #ffffff;
        }
      }
    }
    .heal_list {
      // margin-top: 24px;
      display: flex;
      flex-direction: column;
      // gap: 12px;
      .heal_item {
        &::after {
          display: block;
          content: ' ';
          height: 1px;
          background-color: #ebebeb;
          margin: 12px 0px;
        }
        .heal_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 14px;
          color: #243752;
          .text {
            max-width: 70vw;
          }
        }
        .heal_content {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          height: 38px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 20px;
        }
        &:nth-last-child(1) {
          // background-color: #1dbcc0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .d_card1 {
    margin-top: 16px;
  }
  .d_card2 {
    margin-top: 16px;
    .title {
      margin-left: 12px;
      font-weight: 500;
      font-size: 16px;
      color: #243752;
    }
    .d_card_list {
      margin-top: 24px;
      display: flex;
      align-items: center;
      .mixin_gap_row(9px);
      flex-wrap: nowrap;
      .d_card_item {
        // min-width: 75px;
        flex: 1;
        max-width: calc(25% - 7px);
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px 8px 8px 8px;
        .mixin_gap_column(4px);
        position: relative;
        padding: 6px;
        .d_name {
          font-weight: 400;
          font-size: 13px;
          color: #243752;
        }
        .d_num {
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
        }
        .card_img {
          height: 19px;
        }
      }
      .active {
        background: linear-gradient(90deg, #39dadc 0%, #1dbcc0 100%), #ffffff;
        .d_name {
          color: #ffffff;
        }
        .d_num {
          color: #ffffff;
        }
        &::after {
          display: block;
          content: ' ';
          background-image: url(../../assets/Slice56.png);
          width: 25px;
          height: 12px;
          position: absolute;
          bottom: -14px;
          left: 0;
          right: 0;
          margin: auto;
          background-size: 100%;
          z-index: 9;
        }
      }
    }
    .item_info {
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #ebebeb;
      padding: 12px;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      .mixin_gap_column(10px);
      .quest_item {
        min-height: 42px;
        background: #f3f5f7;
        border-radius: 6px 6px 6px 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-weight: 400;
        font-size: 14px;
        color: #7c8797;
        .text {
          flex: 1;
          // max-width: 70%;
        }
        .time {
          font-weight: 400;
          font-size: 12px !important;
          color: #7c8797 !important;
        }
        .btn {
          width: 60px;
          height: 24px;
          background: #e1e3e5;
          border-radius: 40px 40px 40px 40px;
          font-weight: 500;
          font-size: 12px;
          color: #505f75;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .quest_item_active {
        background: linear-gradient(90deg, #d7f8f8 0%, #d2f2f2 100%);
        .text {
          font-weight: 400;
          font-size: 14px;
          color: #243752;
        }
        .btn {
          background: #1dbcc0;
          color: #ffffff;
        }
      }
    }
    .heal_list {
      display: flex;
      flex-direction: column;
      .popular-item {
        width: 100%;
        box-sizing: border-box;
        background: #ffffff;
        // box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        // border-radius: 12px;
        padding: 12px;
        margin-top: 8px;
        .popular-header {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .popular-header-left {
            font-family:
              PingFang SC,
              PingFang SC;
            font-weight: bold;
            font-size: 14px;
            color: #243752;
            display: flex;
            align-items: center;

            .left-logo {
              width: 24px;
              height: 24px;
              border-radius: 21px;
              margin-right: 6px;
            }
          }
          .popular-header-rigth {
            display: flex;
            align-items: center;
            .rigth-name {
              font-family:
                PingFang SC,
                PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #7c8797;
            }
          }
        }
        .popular-content {
          width: 100%;
          .popular-content-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // margin-top: 24px;
            .popular-img-box {
              width: 60px;
              height: 60px;
              margin-right: 12px;
              flex-shrink: 0;
              .popular-img {
                width: 60px;
                height: 60px;
                border-radius: 8px;
                flex-shrink: 0;
              }
            }
            .iocn {
              width: 20px;
              height: 20px;
              // background: #ff8f50;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 14px;
            }
            .item-content {
              flex: 1;
              box-sizing: border-box;
              padding: 4px 0px;
              display: flex;
              flex-direction: column;
              font-family:
                PingFang SC,
                PingFang SC;
              .content-name {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: bold;
                font-size: 14px;
                color: #243752;
                margin-bottom: 6px;
              }
              .content-text {
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                color: #7c8797;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .popular-item + .popular-item {
        border-top: 1px solid #ebebeb;
      }
      .heal_item {
        background: #ffffff;
        box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        margin-top: 16px;
        .heal_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          font-size: 14px;
          color: #243752;
          .text {
            max-width: 70vw;
          }
        }
        .heal_content {
          margin-top: 12px;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          height: 38px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 20px;
        }
        &:nth-last-child(1) {
          // background-color: #1dbcc0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  .empty_content {
    height: 300px;
  }
  &_dot {
    min-width: 6px;
    // height: 14px;
  }
}

.b_popup {
  .adm-popup-body {
    min-height: 50vh;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
  }
  .colos {
    color: #d9d9d9;
    right: 20px;
    top: 17px;
    font-size: 22px;
    position: absolute;
  }
  .popup_content {
    .popup_header {
      height: 56px;
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 16px;
      color: #243752;
    }
    .popup_list {
      background-color: #f0f3f5;
      padding: 12px;
      display: flex;
      flex-direction: column;
      .mixin_gap_column(12px);
      overflow: auto;
      min-height: 50vh;
      max-height: 65vh;
      .plan_item {
        background: #ffffff;
        box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        .name {
          font-weight: 500;
          font-size: 16px;
          color: #243752;
        }
        .plan {
          background: #f3f5f7;
          border-radius: 6px 6px 6px 6px;
          margin-top: 16px;
          padding: 12px 13px;
          font-weight: 400;
          font-size: 14px;
          color: #243752;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .avtive_icon {
            color: #1dbcc0;
            font-size: 16px;
          }
          .plan_text {
            max-width: 70vw;
          }
        }
        .active_plan {
          background: rgba(29, 188, 192, 0.15);
        }
      }
    }
  }
}
.step2 {
  margin: 269px auto 0;
  width: calc(100vw - 24px);
  position: relative;
  .step2_img {
    width: 100%;
    z-index: 999;
  }
  .step2_btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: 12px;
    width: 112px;
    height: 36px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    width: 12px;
    height: 12px;
    background: #1dbcc0;
    border-radius: 50%;
    position: absolute;
    left: 74px;
    top: -6px;
    .box_con {
      position: absolute;
      width: 20px;
      height: 20px;
      background: #1dbcc0;
      border-radius: 50%;
      opacity: 0.2;
      left: -4px;
      top: -4px;
    }
    .box_line {
      width: 2px;
      height: 60px;
      background: #1dbcc0;
      position: absolute;
      top: -50px;
      left: 5px;
      .step2_text {
        position: absolute;
        top: -36px;
        left: -37px;
        height: 36px;
      }
    }
  }
}
.tabBar_item {
  position: absolute;
  // bottom: 0;
  left: calc(20% + (20% - 60px) / 2);
  width: 60px;
  height: 60px;
  background: #ffffff;
  border-radius: 9px 9px 9px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // ios安全区
  bottom: env(safe-area-inset-bottom);
  bottom: constant(safe-area-inset-bottom);
  img {
    width: 19px;
    height: 19px;
  }
  .tabBar_item_text {
    font-weight: 400;
    font-size: 10px;
    color: #1778ff;
    margin-top: 6px;
  }
}

@root-entry-name: default;