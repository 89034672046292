@import '../../style/vars.less';
.indicator-bottom {
  .pay_box {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    padding: 11px 13px;
    line-height: 1;
    z-index: 999;
    border-top: 1px solid #dce4e5;
    .pay_box_item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 13px;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #7c8797;
      border: 1px #d6d6d6 solid;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      background: @primary-color-3;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.popup-content {
  // padding: 12px 0px;
  display: flex;
  flex-direction: column;
  .input-content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 12px 16px;
    background: #ffffff;
    width: 100%;
    box-shadow: 0px 0px 6px 0px rgba(180, 178, 200, 0.25);

    .left {
      display: flex;
      align-items: flex-end;
      .left_icon {
        width: 32px;
        height: 32px;
      }
      margin-right: 6px;
    }
    .visible {
      background-color: #e8f2ff;
    }
    .center {
      flex: 1;
      margin-right: 6px;
      background: #f2f2f2;
      border-radius: 4px 4px 4px 4px;
      padding: 8px 12px;
      position: relative;
      textarea {
        height: 100% !important;
        font-weight: 400 !important;
        font-size: 14px;
        color: #243752;
        font-family:
          PingFang SC,
          PingFang SC;
        max-height: 84px;
        // overflow: auto;
      }
    }
    .right {
      background: #1778ff;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      width: 58px;
      height: 32px;
    }
  }
  .common {
    overflow: auto;
    padding: 0px 16px;
    transition: all 0.15s linear;
    will-change: height;
    .common-item {
      padding: 16px 0px;
      &:not(:first-child) {
        border-top: 1px solid #EBEBEB;
      }
    }
  }
  .visibility-input {
    width: 0;
    height: 0 !important;
    overflow: hidden;
  }
  .visibility-text {
    visibility: hidden;
    font-family:
      PingFang SC,
      PingFang SC;
    resize: none;
    flex: auto;
    display: block;
    box-sizing: border-box;
    width: calc(100% - 24px);
    max-width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    min-height: 1.5em;
    text-align: left;
    text-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 21px;
    max-height: 84px;
  }
}

@root-entry-name: default;