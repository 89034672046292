@import './vars.less';

body {
  margin: 0;
}

:root:root {
  --adm-color-primary: @primary-color;
}
.ellipsis1{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.ellipsis2 {
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis3 {
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
@root-entry-name: default;