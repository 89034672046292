@import '../../style/vars.less';

.interview_agreement {
  background: #f0f3f5;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .interview_agreement_content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 12px;
    line-height: 2rem;
    padding: 16px 30px;
    font-weight: 400;
    font-size: 16px;
    p {
      margin: 0;
      padding: 0;
    }
    .title {
      font-weight: 600;
      font-size: 26px;
      color: #243752;
      margin-bottom: 24px;
    }
    .red {
      color: #eb5e3d;
    }
  }
  .interview_agreement_bottom {
    width: 100%;
    min-height: 62px;
    // padding: 14px 20px;
    box-sizing: border-box;
    display: flex;
    // background-color: #ffffff;
    align-items: center;
    justify-content: center;
    // ios安全区
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    .interview_agreement_btn {
      width: 80%;
      height: 44px;
      display: flex;
      background: #eb5e3d;
      align-items: center;
      justify-content: center;
      border-radius: 22px;
      color: #fff;
    }
  }
}

@root-entry-name: default;