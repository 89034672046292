@import '../../style/vars.less';

.service_order_confirmation {
  padding: 16px 12px 100px;
  background: #f0f3f5;
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    padding: 20px 16px;
    line-height: 1;
  }
  .card1 {
    .header {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #243752;
    }
    .line {
      height: 1px;
      background-color: #f5f5f5;
      margin: 16px 0px;
    }
    .infos {
      margin-top: 12px;
      display: flex;
      .label {
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
        min-width: 65px;
      }
      .value {
        font-weight: 400;
        font-size: 13px;
        color: #243752;
        margin-left: 24px;
      }
      .value1 {
        color: #1778ff;
      }
    }
    .price_box {
      display: flex;
      margin-top: 18px;
      align-items: center;
      .label {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
      }
      .price {
        font-weight: 600;
        font-size: 12px;
        color: #ff6023;
        margin-left: 24px;
        .unit {
        }
        .price_integer {
          font-size: 18px;
        }
        .price_fractional {
        }
      }
    }
  }
  .card2 {
    font-family:
      PingFang SC,
      PingFang SC;
    margin-top: 16px;
    .header {
      font-weight: 500;
      font-size: 14px;
      color: #243752;
    }
    .content {
      margin-top: 16px;
      font-weight: 400;
      font-size: 13px;
      color: #7c8797;
      line-height: 24px;
      .item_info {
        margin: 6px 0;
      }
    }
  }
  .card3 {
    font-family:
      PingFang SC,
      PingFang SC;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #243752;
    display: flex;
    align-items: center;
    line-height: 1;
    .radio {
      padding: 4px;
    }
    .text {
      margin-left: 8px;
    }
  }
  .card4 {
    margin-top: 16px;
    .header {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #243752;
    }
    .info {
      font-family:
        PingFang SC,
        PingFang SC;
      line-height: 14px;
      font-weight: 400;
      font-size: 12px;
      color: #a7afba;
      margin-top: 10px;
      color: red;
    }
    .list {
      display: flex;
      flex-direction: column;
      // gap: 16px;
      .mixin_gap_column(16px);
      margin-top: 16px;
      .list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        background: #f7f7f7;
        border-radius: 12px 12px 12px 12px;
        .left {
          line-height: 1;
          .name_box {
            display: flex;
            // gap: 6px;
            .mixin_gap_row(6px);
            align-items: center;
            .name {
              font-weight: 400;
              font-size: 15px;
              color: #243752;
            }
            .gender {
              font-weight: 400;
              font-size: 12px;
              width: 24px;
              height: 16px;
              border-radius: 2px 2px 2px 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 3px;
            }
            .man {
              color: #1778ff;
              border: 1px solid #1778ff;
            }
            .woman {
              color: #eb5e3d;
              border: 1px solid #eb5e3d;
            }
            .age {
              font-weight: 400;
              font-size: 12px;
              color: #a7afba;
            }
          }
          .info_box {
            margin-top: 8px;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            color: #505f75;
            img {
              width: 14px;
              height: 14px;
              margin-right: 6px;
            }
            .img2 {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
  .pay_btn {
    width: 335px;
    height: 42px;
    background: @primary-color;
    border-radius: 59px 59px 59px 59px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    position: fixed;
    bottom: 40px;
    margin: auto;
    left: 0;
    right: 0;
  }
}

@root-entry-name: default;