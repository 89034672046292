@import '../../../style/vars.less';

.guide_container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f0f3f5;

  .content {
    padding: 0px 12px;
    .d_card {
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.1);
      border-radius: 12px 12px 12px 12px;
      padding: 12px;
      margin-top: 16px;
      .title {
        font-weight: 600;
        font-size: 16px;
        color: #243752;
      }
      .d_card_list {
        margin-top: 24px;
        display: flex;
        align-items: center;
        .mixin_gap_row(9px);
        flex-wrap: nowrap;
        .d_card_item {
          // min-width: 75px;
          flex: 1;
          max-width: calc(25% - 7px);
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #ebebeb;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .mixin_gap_column(4px);
          position: relative;
          padding: 6px;
          .d_name {
            font-weight: 400;
            font-size: 13px;
            color: #243752;
          }
          .d_num {
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
          }
          .card_img {
            height: 19px;
          }
        }
        .active {
          background: rgba(23, 120, 255, 0.1);
          border: 1px solid #1778ff;
          // .d_name {
          //   color: #ffffff;
          // }
          // .d_num {
          //   color: #ffffff;
          // }
          &::after {
            display: block;
            content: ' ';
            background-image: url(../../../assets/Slice56.png);
            width: 25px;
            height: 12px;
            position: absolute;
            bottom: -14px;
            left: 0;
            right: 0;
            margin: auto;
            background-size: 100%;
            z-index: 9;
          }
        }
      }
      .item_info {
        background: #ffffff;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #ebebeb;
        padding: 12px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        .quest_item {
          border-radius: 6px 6px 6px 6px;
          padding: 7px 10px;
          font-weight: 400;
          font-size: 14px;
          color: #7c8797;
          .title_box {
            display: flex;
            font-weight: 400;
            font-size: 14px;
            color: #243752;
            .text {
              max-width: 70%;
            }
          }
          .btn {
            width: 48px;
            height: 20px;
            background: #e1e3e5;
            border-radius: 4px 4px 4px 4px;
            font-weight: 400;
            font-size: 12px;
            color: #505f75;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 4px;
          }
          .time_box {
            display: flex;
            margin-top: 6px;
            font-weight: 400;
            font-size: 12px;
            color: #a7afba;
            .success_time {
              margin-left: 16px;
            }
          }
        }
        .quest_item_active {
          .btn {
            background: rgba(23, 120, 255, 0.1);
            color: #1778ff;
          }
        }
      }
      .heal_list {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        // gap: 12px;
        .heal_item {
          &::after {
            display: block;
            content: ' ';
            height: 1px;
            background-color: #ebebeb;
            margin: 12px 0px;
          }
          .heal_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 14px;
            color: #243752;
          }
          .heal_content {
            margin-top: 12px;
            font-weight: 400;
            font-size: 12px;
            color: #7c8797;
            height: 38px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-height: 20px;
          }
          &:nth-last-child(1) {
            // background-color: #1dbcc0;
            &::after {
              display: none;
            }
          }
        }
      }
    }
    .empty_content {
      height: 500px;
    }
  }
}

@root-entry-name: default;