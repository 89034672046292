.HealthEducation-container {
  width: 100%;
  height: calc(100vh - 0px);
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .search {
    --adm-color-primary: #4f8ef5;
    margin-bottom: 6px;
  }
  .HealthEducation-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .empty {
      font-weight: 400;
      font-size: 14px;
      color: #7c8797;
      text-align: center;
      margin-top: 16px;
    }
    .prompt {
      font-weight: 400;
      font-size: 14px;
      color: #7c8797;
      text-align: center;
      margin-top: 16px;
    }
    .video-item {
      display: flex;
      align-items: center;
      padding: 16px 0px;
      &:not(:last-child) {
        border-bottom: 1px solid #EBEBEB;
      }
      .video-header {
        width: 60px;
        height: 60px;
        border-radius: 8px 8px 8px 8px;
        margin-right: 16px;
        position: relative;
        .img {
          width: 60px;
          height: 60px;
          border-radius: 8px 8px 8px 8px;
          // border: 1px solid red;
        }
        .title {
          position: absolute;
          bottom: 5px;
          right: 5px;
          width: 28px;
          height: 14px;
          background: rgba(0, 0, 0, 0.5);
          font-weight: 400;
          font-size: 10px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .video-content {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 10px;
        .header-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-weight: 500;
          font-size: 15px;
          color: #243752;
          margin-bottom: 6px;
        }
        .header-bottom {
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          color: #7c8797;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

@root-entry-name: default;