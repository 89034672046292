@import '../../../style/vars.less';
.CaseManagement-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
  background: #f0f3f5;
  padding: 128px 0px 30px 0px;
  .CaseManagement-header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;

    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(180, 178, 200, 0.25);
    box-sizing: border-box;
    // padding: 6px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
    .search {
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      border-radius: 16px;
      --border-radius: 16px;
      --adm-color-primary: #4f8ef5;
      width: calc(100% - 24px);
      margin: 6px auto;
    }
    .tab {
      display: flex;
      width: 100%;
      height: 44px;
      border-bottom: 1px solid #ebebeb;
      padding: 0px 12px;
      .tab-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        color: #505f75;
      }
      .activeTab {
        font-weight: 500;
        font-size: 16px;
        color: #1778ff;
      }
    }
    .CasePicker-box {
      width: 100%;
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 12px;
      .CasePicker-contaniner {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .CasePicker-contaniner + .CasePicker-contaniner {
        border-left: 1px solid #d4d5d6;
      }
    }
    .case {
      width: 100%;
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 12px;
      .case-item {
        width: 72px;
        height: 24px;
        background: #ececf8;
        border-radius: 15px 15px 15px 15px;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #505f75;
      }
      .active-case-item {
        color: #ffffff;
        background: #1778ff;
      }
      .adm-badge,
      .adm-badge-fixed {
        top: 2px;
        right: 2px;
      }
      .next {
        margin-left: 12px;
      }
    }
  }
  .CaseManagement-content {
    width: 100%;
    background: #f0f3f5;
  }
  .my_empty {
    height: 500px;
  }
}
.CasePicker-contaniner {
  display: flex;
  align-items: center;
  .title {
    font-weight: 400;
    font-size: 12px;
    color: #243752;
    margin-right: 4px;
  }
  .triangle {
    width: 10px;
    height: 10px;
  }
}
.CasePicker-contaniner + .CasePicker-contaniner {
  margin-left: 12px;
}
.CaseCard {
  width: 100%;
  margin-top: 12px;
  background: #ffffff;
  padding: 16px 20px 20px 16px;
  line-height: 1;
  .CaseCard-header {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .text {
        font-size: 14px;
        flex-shrink: 0;
        font-weight: 400;
        color: #243752;
      }
      .name {
        font-weight: 500;
        font-size: 16px;
        color: #243752;
      }
      .text + .text {
        margin-left: 10px;
      }
    }
    .right {
      background: rgba(235, 135, 61, 0.1);
      padding: 4px 8px;
      display: flex;
      align-items: center;
      .ietm-img {
        width: 14px;
        height: 14px;
      }
      .item-title {
        font-weight: 400;
        font-size: 13px;
        color: #eb5e3d;
        margin-left: 4px;
      }
    }
  }
  .CaseCard-content {
    .info-item {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .left {
        min-width: 59px;
        font-weight: 400;
        font-size: 13px;
        color: #7c8797;
      }
      .right {
        margin-left: 16px;
        font-weight: 400;
        font-size: 13px;
        color: #243752;
      }
    }
  }
  .CaseCard-bottom {
    background: #ffffff;
    border-radius: 0px 12px 12px 12px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-top: 6px;
    .bottom-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      .ietm-img {
        width: 24px;
        height: 24px;
        margin-bottom: 6px;
      }
      .item-title {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
      }
    }
    .bottom-item + .bottom-item {
      margin-left: 20px;
    }
  }
  .case-individual {
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    border-radius: 0px 12px 12px 12px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 6px;

    .mixin_gap_row(8px);
    .individual-item {
      width: fit-content;
      background: rgba(235, 135, 61, 0.1);
      flex-direction: column;
      align-items: center;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 12px;
      margin-top: 6px;
      color: #eb5e3d;
      .reportTime {
        color: #000;
        color: #7c8797;
        margin-top: 4px;
      }
    }
  }
}

@root-entry-name: default;