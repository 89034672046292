@import '../../style/vars.less';

.service-details-container {
  width: 100%;
  height: calc(100vh - 0px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 98px;
  box-sizing: border-box;
  background-color: #f0f3f5;
  .appraise_num {
    font-weight: 500;
    font-size: 16px;
    color: #243752;
    padding: 16px 20px;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    margin-top: 16px;
    width: calc(100% - 24px);
    margin: auto;
    box-sizing: border-box;
    .appraise_card {
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(192, 192, 192, 0.1);
      padding: 16px;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      &:not(:last-child)::after {
        display: inline-block;
        height: 1px;
        background-color: #f5f5f5;
        content: ' ';
        width: calc(100% - 32px);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      .appraise_card_header {
        display: flex;
        .avatar {
          img {
            width: 32px;
            height: 32px;
            border-radius: 21px 21px 21px 21px;
          }
        }
        .appraise_card_header_right {
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 10px;
          .top {
            display: flex;
            justify-content: space-between;

            .user {
              .name {
                font-weight: 400;
                font-size: 12px;
                color: #7c8797;
              }
            }
          }
        }
        .star {
          margin-top: 5px;
        }
      }
      .appraise_card_content {
        font-weight: 400;
        font-size: 14px;
        color: #243752;
        margin-left: 42px;
        margin-top: 10px;
        .Ellipsis {
          --adm-color-primary: #a7afba;
        }
      }
      .date {
        font-weight: 400;
        font-size: 12px;
        color: #a7afba;
      }
    }
  }

  .bottom {
    width: 100%;
    min-height: 62px;
    padding: 14px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 99;
    background-color: #ffffff;
    padding-bottom: 30px;
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 20px;
      .text {
        font-weight: 400;
        font-size: 12px;
        color: #505f75;
      }
      .icon {
        width: 26px;
        height: 26px;
      }
    }
    .rigth {
      cursor: pointer;
      width: 149px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @primary-color;
      border-radius: 59px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}

@root-entry-name: default;