.notice-container {
  width: 100%;
  height: 100%;
  background: #ecf8f8;
  box-sizing: border-box;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  line-height: 1;
  .notice-card {
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow:
      0px 0px 6px 0px rgba(153, 153, 153, 0.1),
      0px 4px 6px 0px rgba(192, 192, 192, 0.1);
    border-radius: 12px 12px 12px 12px;
    .notice-card-item {
      display: flex;
      padding: 12px 0px;
      flex-direction: column;
      .top {
        display: flex;
      }
      &:not(:first-child) {
        border-top: 1px solid #ebebeb;
      }
      .notice-avatar {
        width: 42px;
        height: 42px;
        background: #1dbcc0;
        border-radius: 34px 34px 34px 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      .notice-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 10px;
        .totice_title {
          display: flex;
          justify-content: space-between;
          .title {
            font-weight: 500;
            font-size: 14px;
            color: #243752;
            align-items: center;
            display: flex;
          }
          .date {
            font-weight: 400;
            font-size: 13px;
            color: #7c8797;
          }
        }
        .totice_content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;

          .content {
            font-weight: 400;
            font-size: 14px;
            color: #7c8797;
            max-width: calc(100vw - 154px);
            // width: 70%;
          }
          .read_num {
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e32525;
            border-radius: 50px 50px 50px 50px;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
          }
        }
      }
      .totice_visitor {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .patientName {
          font-weight: 400;
          font-size: 12px;
          color: #1778ff;
          background: rgba(23, 120, 255, 0.1);
          border-radius: 21px 21px 21px 21px;
          padding: 3px 7px;
          max-width: 120px;
          flex-shrink: 0;
        }
        .visitorName {
          font-weight: 400;
          font-size: 12px;
          color: #eb5e3d;
          background: rgba(235, 94, 61, 0.1);
          border-radius: 21px 21px 21px 21px;
          padding: 3px 7px;
          margin-left: 10px;
        }
      }
    }
  }
}

@root-entry-name: default;